import React, { useState, useRef, useEffect } from 'react'
import { Card } from 'primereact/card';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { Chips } from 'primereact/chips';
import { getAction, updateAction } from '../../../../services/Action';
import { useTranslation } from 'react-i18next';

export default function Leads() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const toast = useRef(null);
    const [firstName, setFirstName] = useState(false);
    const [lastName, setLastName] = useState(false);
    const [email, setEmail] = useState(false);
    const [phone, setPhone] = useState(false);
    const [adresse, setAdresse] = useState(false);
    const [data, setData] = useState([]);


    function save() {
        const params = {
            data,
            lastName,
            firstName,
            email,
            phone,
            adresse,
        }

        updateAction(slug, params).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });

        }).catch((err) => {
            if (err.response.status == 500) {
                toast.current.show({ severity: 'error', summary: 'Internal Server Error', detail: err.response.data.error, life: 3000 });
            }
        })
    }
    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('leads')
    }, [])

    useEffect(() => {
        getAction(slug).then((res) => {
            if (res.data.action) {
                setFirstName(res.data.action.firstName == 1 ? true : false)
                setLastName(res.data.action.lastName == 1 ? true : false)
                setEmail(res.data.action.email == 1 ? true : false)
                setPhone(res.data.action.phone == 1 ? true : false)
                setAdresse(res.data.action.adresse == 1 ? true : false)
                setData(res.data.action.params ? JSON.parse(res.data.action.params) : [])
            } else {
                setFirstName(false);
                setLastName(false);
                setEmail(false);
                setPhone(false);
                setAdresse(false);
                setData([])
            }
        })

    }, [slug])

    return (
        <>
            <Toast ref={toast} />
            <Card title={t('leads')}>
                <p>
                    {t('note_leads')}
                </p>
                <div className='mx-5'>
                    <div className="flex flex-col gap-2 py-1 mt-4 border-b ">
                        <div className=" flex flex-row items-center justify-between ">
                            <label className="text-md block pb-2 font-medium text-zinc-700">{t('first_name')}</label>
                            <InputSwitch checked={firstName} onChange={(e) => setFirstName(e.value)} />
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-1 mt-4 border-b ">
                        <div className=" flex flex-row items-center justify-between ">
                            <label className="text-md block pb-2 font-medium text-zinc-700">{t('last_name')}</label>
                            <InputSwitch checked={lastName} onChange={(e) => setLastName(e.value)} />
                        </div>
                    </div>


                    <div className="flex flex-col gap-2 py-1 mt-4 border-b ">
                        <div className=" flex flex-row items-center justify-between ">
                            <label className="text-md block pb-2 font-medium text-zinc-700">{t('email')}</label>
                            <InputSwitch checked={email} onChange={(e) => setEmail(e.value)} />
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 py-1 mt-4 border-b ">
                        <div className=" flex flex-row items-center justify-between ">
                            <label className="text-md block pb-2 font-medium text-zinc-700">{t('phone')}</label>
                            <InputSwitch checked={phone} onChange={(e) => setPhone(e.value)} />
                        </div>
                    </div>


                    <div className="flex flex-col gap-2 py-1 mt-4 border-b ">
                        <div className=" flex flex-row items-center justify-between ">
                            <label className="text-md block pb-2 font-medium text-zinc-700">{t('adresse')}</label>
                            <InputSwitch checked={adresse} onChange={(e) => setAdresse(e.value)} />
                        </div>
                    </div>

                    <div className="w-full mt-4">
                        <label htmlFor="name" className="block text-900 font-medium mb-2">{t('what_information_do_you_want_about_the_visitors')}</label>
                        <Chips value={data} onChange={(e) => setData(e.value)} separator="," className="w-full chips"
                            placeholder=''

                        />


                    </div>
                    <div className="w-full mt-3 flex justify-end" >
                        <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={save} />
                    </div>

                </div>

            </Card>
        </>
    )
}
