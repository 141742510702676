import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const uploadFileAi = async (files) => {
    await Csrf.getCookie();
    return await Api.post("/files", files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}


export const uploadFileSource = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post("/files/" + slug, params, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export const storeChatAi = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/chatAi`, params);
}


export const updateChatAi = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.put(`/chatAi/${slug}`, params);
}

export const deleteChatAi = async (id) => {
    await Csrf.getCookie();
    return await Api.delete(`/chatAi/${id}`);
}

export const fetchChatAi = async () => {
    await Csrf.getCookie();
    return await Api.get(`/chatAi`);
}

export const getAi = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/getchat/${slug}`);
}

export const updateAiColors = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.put(`/updateAiColors/${slug}`, params);
}

export const editChatAi = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/chatAi/${slug}/edit`);
}

export const editQA = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.put(`/editQA/${slug}`, params);
}

export const AddQA = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post(`/addQA/${slug}`, params);
}

export const getQA = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/getQA/${slug}`);
}


export const addWebsite = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post(`/add-website/${slug}`, params);
}



export const getWebsite = async (slug, page) => {
    await Csrf.getCookie();
    return await Api.get(`/get-website/${slug}`, {
        params: {
            page,
        }
    });
}

export const deleteFileScrape = async (id) => {
    await Csrf.getCookie();
    return await Api.delete(`/delete-file-scrape/${id}`);
}


export const fChatLogs = async (slug, date, filter, page, perPage) => {
    await Csrf.getCookie();
    return await Api.get(`/chatLogs/${slug}`, {
        params: {
            page,
            perPage,
            ...{ date, filter }
        }
    });
}

export const chatLogWhitId = async (id) => {
    await Csrf.getCookie();
    return await Api.get(`/chatLogWhitId/${id}`);
}

export const LeadLogWhitId = async (id) => {
    await Csrf.getCookie();
    return await Api.get(`/leadLogWhitId/${id}`);
}



export const fLeads = async (slug, date, page, perPage, customDate = null, search) => {

    await Csrf.getCookie();

    const params = {
        page,
        perPage,
        search,
        date,
    };

    if (customDate !== null) {
        params.customDate = customDate;
    }
    return await Api.get(`/leads/${slug}`, { params });
}



export const editLead = async (id, params) => {
    await Csrf.getCookie();
    return await Api.put(`/leads/${id}`, params,)
}

export const destroyLead = async (id) => {
    await Csrf.getCookie();
    return await Api.delete(`/leads/${id}`,)
}

export const destroyConversation = async (params) => {

    await Csrf.getCookie();
    return await Api.post(`/destroyConversation`, params)
}