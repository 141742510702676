import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { getWorkingHoursStore, storeWorkingHours } from '../../../services/Appointment';
import { useSelector } from 'react-redux';
import Calendar from './Calendar';

export default function AppointmentIndex() {
  const { t } = useTranslation();
  const toast = useRef(null);
  const toastMsg = useRef(null);
  const userId = useSelector(state => state.auth.user.id)
  const [selectedDuration, setSelectedDuration] = useState(30);
  const [selectedDays, setSelectedDays] = useState([]);
  const [timeInputs, setTimeInputs] = useState({});
  const [isDisabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tab, setTap] = useState("list");
  const [loading, setLoading] = useState(false);

  const durations = [30, 45, 60];

  const Week = [
    { name: t('Monday'), value: 'Monday' },
    { name: t('Tuesday'), value: 'Tuesday' },
    { name: t('Wednesday'), value: 'Wednesday' },
    { name: t('Thursday'), value: 'Thursday' },
    { name: t('Friday'), value: 'Friday' },
    { name: t('Saturday'), value: 'Saturday' },
    { name: t('Sunday'), value: 'Sunday' },
  ];


  useEffect(() => {
    getWorkingHoursStore(userId).then(res => {
      const data = res.data;
      const newSelectedDays = data.map(dayData => dayData.day);
      const newTimeInputs = {};
      data.forEach(dayData => {
        setSelectedDuration(dayData.duration)
        newTimeInputs[dayData.day] = dayData.time_ranges.map(timeRange => ({
          start: timeRange.start_time.substring(0, 5),
          end: timeRange.end_time.substring(0, 5)
        }));
      });

      setSelectedDays(newSelectedDays);
      setTimeInputs(newTimeInputs);
    }).catch(error => {
      toast.error('Failed to load working hours: ' + error.message);
    });
  }, [userId]);

  const handleChangeDuration = (value) => {
    setSelectedDuration(value);
  };

  const handleCheckboxChange = (event, day) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedDays([...selectedDays, day]);
      setTimeInputs((prev) => ({
        ...prev,
        [day]: [{ start: '09:00', end: '17:00' }],
      }));
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
      setTimeInputs((prev) => ({ ...prev, [day]: [] }));
    }
  };

  const addNewTimeColumn = (day) => {
    setTimeInputs((prev) => {
      const previousColumns = prev[day] || [];

      let newStartTime = '09:00';
      let newEndTime = '17:00';

      if (previousColumns.length > 0) {
        const lastEndTime = previousColumns[previousColumns.length - 1].end;

        // Parse the time and add 1 hour
        const [hours, minutes] = lastEndTime.split(':').map(Number);
        let newStartHour = (hours + 1) % 24; // Keep it within 24 hours

        // Format the time correctly
        newStartTime = `${newStartHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        let newEndHour = (newStartHour + 1) % 24;
        newEndTime = `${newEndHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }

      return {
        ...prev,
        [day]: [...previousColumns, { start: newStartTime, end: newEndTime }],
      };
    });
  };

  const removeTimeColumn = (day, index) => {
    setTimeInputs((prev) => {
      const updatedTimes = [...prev[day]];
      updatedTimes.splice(index, 1);

      if (updatedTimes.length === 0) {
        setSelectedDays((prevDays) => prevDays.filter((d) => d !== day));
      }

      return { ...prev, [day]: updatedTimes };
    });
  };

  const validateInput = (day, index, type, value, event) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Validate time format HH:MM
    const currentTimes = timeInputs[day] || [];

    // Clear any previous toast messages
    toast.current.clear();

    // Validate time format
    if (!regex.test(value)) {
      setErrorMessage(t('invalid_time_format'));
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: t('invalid_time_format'), life: 30000 });
      event.target.classList.add('p-invalid');
      updateInvalidFlag(day, index, type, false, value);
      return;
    }

    // Valid format, remove error state
    event.target.classList.remove('p-invalid');
    updateInvalidFlag(day, index, type, true, value);

    // If end time, check it's after start time
    if (type === 'end') {
      const start = currentTimes[index].start;
      if (value <= start) {
        handleTimeError(day, index, type, value, 'select_later_end_time');
        return;
      }
    }

    // If start time, check for overlap with previous time block
    if (type === 'start' && index > 0) {
      const previousEnd = currentTimes[index - 1].end;
      if (value <= previousEnd) {
        handleTimeError(day, index, type, value, 'start_after_previous_end');
        return;
      }
    }

    // Check for overlap with any existing time blocks
    if (currentTimes.some((time, i) => i !== index && time.start < value && value < time.end)) {
      handleTimeError(day, index, type, value, 'time_overlap');
      return;
    }

    // Success message if everything is correct
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Time input valid!', life: 3000 });
  };

  const handleTimeError = (day, index, type, value, errorMessageKey) => {
    setErrorMessage(t(errorMessageKey));
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: t(errorMessageKey), life: 30000 });
    event.target.classList.add('p-invalid');
    updateInvalidFlag(day, index, type, false, value);
  };


  const updateInvalidFlag = (day, index, type, isValid, value) => {
    setTimeInputs((prev) => {
      const updatedTimes = [...prev[day]];
      updatedTimes[index] = {
        ...updatedTimes[index],
        [type]: value,
        [`${type}Invalid`]: !isValid,
      };
      return { ...prev, [day]: updatedTimes };
    });
  };


  function validateBlur(day) {
    const times = timeInputs[day];
    const updatedTimes = [...times]; // Clone the times array for the day

    times.forEach((time, idx) => {
      if (idx === 0) {
        // Handle the first time input separately
        const start = time.start;
        const end = time.end;
        updatedTimes[idx].endInvalid = !(start < end); // Set invalid flag directly
      } else {
        // Handle the start time comparison with previous end time
        const prevEnd = times[idx - 1].end;
        const start = time.start;
        updatedTimes[idx].startInvalid = !(prevEnd < start); // Set invalid flag directly

        // Handle the end time comparison with its start time
        const end = time.end;
        updatedTimes[idx].endInvalid = !(start < end); // Set invalid flag directly
      }
    });

    setTimeInputs(prev => ({ ...prev, [day]: updatedTimes }));

  }


  // Helper function to convert time HH:MM to minutes
  function convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }



  const save = async () => {
    setLoading(true);
    const params = {
      working_hours: [],
      duration: Number(selectedDuration)
    };

    selectedDays.forEach(day => {
      const dayData = {
        day,
        time_ranges: timeInputs[day].map(time => ({
          start_time: time.start,
          end_time: time.end
        }))
      };
      params.working_hours.push(dayData);
    });


    await storeWorkingHours(userId, params).then(res => {
      toastMsg.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
    }).finally(() => setLoading(false))

  }


  useEffect(() => {
    let week = document.getElementById('week');
    if (week) {
      let invalid = week.getElementsByClassName('p-invalid')
      if (invalid.length > 0 || selectedDays.length == 0) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }, [timeInputs]);

  useEffect(() => {
    document.title = 'DialogEase - ' + t('appointment')
  }, [])


  return (
    <>
      <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
        <h4 className="my-1 text-3xl font-bold">{t('appointment_management')}</h4>
      </div>

      <Card className='mb-4' title={() => <div className='flex gap-2'>
        <Button label={t('list_presentation')} className='w-full' outlined={tab !== "list"} onClick={() => setTap('list')} />
        <Button label={t('calendar_view')} className='w-full' outlined={tab !== "view"} onClick={() => setTap('view')} />
      </div>}>
        <div className='mt-4'>
          <div>
            {
              tab === 'list' ?
                <div>
                  <div className="mb-3">
                    <h4 htmlFor="duration" className="font-bold mb-2">
                      {t('actif_pour')}
                    </h4>
                    <Dropdown
                      inputId='duration'
                      value={selectedDuration}
                      onChange={(e) => handleChangeDuration(e.value)}
                      options={durations}
                      placeholder={t('select_a_duration')}
                      editable
                    />
                  </div>

                  <div className="">
                    <h4 className="font-bold">{t('weekly_hours')}</h4>
                  </div>
                  <div className="mt-3" id='week'>
                    {Week.map((day, index) => (
                      <div key={index} className="grid col gap-2 py-3 items-start">
                        <div className="flex gap-2 col-2  items-center h-4rem">
                          <Checkbox
                            inputId={`day-${day.value}`}
                            name={`day-${day.value}`}
                            value={day.value}
                            checked={selectedDays.includes(day.value)}
                            onChange={(e) => handleCheckboxChange(e, day.value)}
                          />
                          <label htmlFor={`day-${day.value}`} className="block font-medium ">
                            {day.name}
                          </label>
                        </div>

                        <div className="col space-y-2 ">
                          {selectedDays.includes(day.value) ?
                            timeInputs[day.value]?.map((timeInput, idx) => (
                              <div key={idx} className=''>
                                <div className="flex gap-2 items-center">
                                  <InputText

                                    value={timeInput.start || ''}
                                    onChange={(e) => validateInput(day.value, idx, 'start', e.target.value, e)}
                                    onBlur={(e) => validateBlur(day.value, idx, 'start', e.target.value, e)}
                                    className={`w-6rem ${timeInput.startInvalid && 'p-invalid'} `}
                                    placeholder="HH:MM"
                                  />
                                  -
                                  <InputText
                                    value={timeInput.end || ''}
                                    onChange={(e) => validateInput(day.value, idx, 'end', e.target.value, e)}
                                    onBlur={(e) => validateBlur(day.value, idx, 'end', e.target.value, e)}
                                    className={`w-6rem ${timeInput.endInvalid && 'p-invalid'} `}
                                    placeholder="HH:MM"
                                  />
                                  <Button
                                    icon="pi pi-times"
                                    severity="danger"
                                    aria-label="delete"
                                    rounded text
                                    onClick={() => removeTimeColumn(day.value, idx)}
                                  />
                                </div>
                              </div>
                            ))
                            :
                            <span className='flex items-center max-sm:justify-end max-md:justify-center h-3rem'>{t('indisponible')}</span>
                          }
                        </div>

                        {selectedDays.includes(day.value) && (
                          <div className="col">
                            <Button
                              icon="pi pi-plus"
                              severity="secondary"
                              aria-label="add"
                              onClick={() => addNewTimeColumn(day.value)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className='flex justify-end'>
                    <Button label={t('save')} className='min-w-32' onClick={save} loading={loading} disabled={isDisabled} />
                  </div>
                </div> :

                <Calendar duration={'00:' + selectedDuration + ':00'} />
            }
          </div>
        </div>
      </Card>
      <Toast ref={toast} position="bottom-right" />
      <Toast ref={toastMsg} />
    </>
  );
}
