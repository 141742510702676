import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    return (
        <>
            <div className='w-full md:w-20rem py-5  px-3 p-card bg-[f0f0f1]  notBorderB min-h-screen'>
                <ul className="space-y-3">
                    <li>
                        <NavLink to="users" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-100 ' : 'text-gray-900'} hover:bg-zinc-100  group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                            <i className="pi pi-users"></i>
                            {t('users')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="translation" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-100 ' : 'text-gray-900'} hover:bg-zinc-100  group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                            <i className="pi pi-language"></i>
                            {t('transactions')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="faq" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-100 ' : 'text-gray-900'} hover:bg-zinc-100  group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                            <i className="pi pi-question"></i>
                            {t('faq')}
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="tickets" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-100 ' : 'text-gray-900'} hover:bg-zinc-100  group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                            <i className="pi pi-ticket"></i>
                            {t('tickets')}
                        </NavLink>
                    </li>

                </ul>
            </div>
        </>
    )
}
