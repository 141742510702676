import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { useTranslation } from 'react-i18next'
import { InputSwitch } from 'primereact/inputswitch';
import { NavLink, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { destroyWebhook, FUpdateStatus, getWebhooks, storeWebhook, testWebhook, updateWebhook } from '../../../../services/Webhook';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Webhooks() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const toast = useRef(null);
    const [visibleTest, setVisibleTest] = useState(false);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState(false);
    const [name, setName] = useState('');
    const [webhooks, setWebhooks] = useState([]);
    const [url, setUrl] = useState('');
    const [id, setID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingTest, setLoadingTest] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({ name: null, type: null, url: null });
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        fetchWebhooks();
    }, [slug])

    const dataTest = {
        last_name: "John",
        first_name: "Doe",
        email: "john.doe@example.com",
        phone: "1234567890",
        address: "123 Main St",
        data: {
            key1: "value1",
            key2: "value2",
        }
    }

    async function sendTest() {

        setLoadingTest(true);
        let params = {
            url,
            type: type ? 'POST' : 'GET',
            dataTest,
        }

        testWebhook(params)
            .then((res) => {
                setResponse(res.data);
                setVisibleTest(true);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    const newErrors = {};
                    if (err.response.data.errors && err.response.data.errors.url) {
                        newErrors.url = err.response.data.errors.url[0];
                    }
                    setErrors(newErrors);
                }
            })
            .finally(() => {
                setLoadingTest(false);
            });
    }

    function save() {

        if (isEdit) {
            update();
            return;
        }

        setLoading(true);
        let params = {
            name,
            url,
            type: type ? 'POST' : 'GET',
            slug_ai: slug,
        }

        storeWebhook(params)
            .then((res) => {
                fetchWebhooks();
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
                close();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    const newErrors = {};
                    if (err.response.data.errors && err.response.data.errors.name) {
                        newErrors.name = err.response.data.errors.name[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.type) {
                        newErrors.type = err.response.data.errors.type[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.url) {
                        newErrors.url = err.response.data.errors.url[0];
                    }

                    setErrors(newErrors);
                } else if (err.response && err.response.status === 403) {
                    toast.current.show({
                        severity: 'warn',
                        summary: "Warning",
                        detail: err.response.data.message,
                        life: 8000,
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function update() {
        setLoading(true);
        let params = {
            name,
            url,
            type: type ? 'POST' : 'GET',
        }

        updateWebhook(id, params)
            .then((res) => {

                fetchWebhooks();
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
                close();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    const newErrors = {};
                    if (err.response.data.errors && err.response.data.errors.name) {
                        newErrors.name = err.response.data.errors.name[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.type) {
                        newErrors.type = err.response.data.errors.type[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.url) {
                        newErrors.url = err.response.data.errors.url[0];
                    }

                    setErrors(newErrors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }


    function fetchWebhooks() {
        getWebhooks(slug).then((response) => {
            setWebhooks(response.data.webhooks);
            setDisabled(response.data.disabled);
        });
    }


    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('webhooks')
    }, [])


    const title = () => {
        return (
            <div className='flex justify-between'>
                <h1>{t('webhooks')}</h1>
                <Button label={t('add_webhook')} disabled={disabled} onClick={() => setVisible(true)} />
            </div>
        )
    }


    function edit(data) {
        setID(data.id);
        setIsEdit(true)
        setVisible(true);
        setName(data.name);
        setType(data.type === 'POST');
        setUrl(data.url);
    }

    function deleteWebhook(id) {
        destroyWebhook(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            fetchWebhooks();
        })
    }

    function closeTest() {
        setVisibleTest(false);
        setResponse({});
        setErrors({ name: null, type: null, url: null });
    }

    function close() {
        setID(null);
        setVisible(false);
        setIsEdit(false);
        setName('');
        setType(false);
        setUrl('');
        setErrors({ name: null, type: null, url: null });
    }

    const actions = (data) => {
        return (
            <div className='flex gap-1'>
                <Button icon="pi pi-pen-to-square" severity="info" rounded aria-label="edit" onClick={() => edit(data)} />
                <Button icon="pi pi-trash" rounded severity="danger" aria-label="delete" onClick={() => deleteWebhook(data.id)} />
            </div>
        );
    };


    function updateStatus(id) {
        FUpdateStatus(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            fetchWebhooks();
        })
    }

    const status = (data) => {

        const status = data.status == 1 ? true : false;

        return (
            <div>
                <InputSwitch checked={status} onChange={() => updateStatus(data.id)} />
            </div>
        );
    };

    return (
        <>
            <Card title={title} className='min-h-[700px]'>
                <DataTable value={webhooks} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="name" header={t('name_webhook')}></Column>
                    <Column field="type" header={t('sending_type')}></Column>
                    <Column field="url" header={t('url')}></Column>
                    <Column field="status" header={t('status')} body={status}></Column>
                    <Column field="" header={t('actions')} body={actions}></Column>
                </DataTable>
            </Card>

            <Dialog header={t('send_response')} visible={visibleTest} onHide={() => { if (!visibleTest) return; closeTest(); }}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>

                <div>
                    <p className="text-gray-500 dark:text-gray-400">
                        {t('request')}
                    </p>
                    <blockquote className="px-4 py-2 mt-4 border-s-4 border-gray-300 bg-gray-5">
                        <pre className="text-lg  leading-relaxed text-gray-900">
                            <span className='text-primary font-bold'>{type ? 'POST' : 'GET'}</span>  {url}
                        </pre>
                    </blockquote>
                    <blockquote className="px-4 py-2 mt-2 border-s-4 border-gray-300 bg-gray-5">
                        <pre className="text-lg  leading-relaxed text-gray-900">
                            <span className='text-primary font-bold'>Content-Type</span>: application/json
                        </pre>
                    </blockquote>
                </div>

                <div className='mt-6'>
                    <p className="text-gray-500 dark:text-gray-400">
                        {t('data_send_example')}
                    </p>
                    <blockquote className="px-4 py-2 my-4 border-s-4 border-gray-300 bg-gray-5">
                        <pre className="text-lg font-medium leading-relaxed text-gray-900">
                            {JSON.stringify(dataTest, null, 2)}
                        </pre>
                    </blockquote>
                </div>

                <div className='mt-6'>
                    <p className="text-gray-500 dark:text-gray-400">
                        {t('response')}
                    </p>
                    <blockquote className="px-4 py-2 my-4 border-s-4 border-gray-300 bg-gray-5">
                        <pre className="text-lg font-medium leading-relaxed text-gray-900">
                            {JSON.stringify(response, null, 2)}
                        </pre>
                    </blockquote>
                </div>
            </Dialog>

            <Dialog header={t('add_webhook')} visible={visible} onHide={() => { if (!visible) return; close(); }}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form>
                    <div className='flex gap-4'>
                        <div className='w-full mt-4'>
                            <label className="block text-sm font-medium mb-2">{t('name_webhook')}</label>
                            <InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' invalid={errors.name} />
                            {errors.name && (
                                <small className="text-red-500">{errors.name}</small>
                            )}
                        </div>

                        <div className='w-full mt-4'>
                            <label className="block text-sm font-medium mb-2">{t('sending_type')}</label>
                            <div className='flex items-center ltr:justify-start rtl:justify-end gap-4 h-12  dir-ltr'>
                                <span onClick={() => setType(false)} className={`select-none cursor-pointer ${!type && 'text-primary  font-bold'}`}>GET</span>
                                <InputSwitch className='sending_type' checked={type} onChange={(e) => setType(e.value)} />
                                <span onClick={() => setType(true)} className={`select-none cursor-pointer ${type && 'text-primary  font-bold'}`}>POST</span>
                            </div>
                        </div>
                    </div>

                    <div className='w-full mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('url_webhook')}</label>
                        <InputText value={url} onChange={(e) => setUrl(e.target.value)} className='w-full' invalid={errors.url} />
                        {errors.url && (
                            <small className="text-red-500">{errors.url}</small>
                        )}
                    </div>

                    <div className='flex justify-between mt-4'>
                        <Button label={t('send_test')} type='button' severity='warning' className='min-w-32' onClick={sendTest} loading={loadingTest} />
                        <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={save} loading={loading} />
                    </div>

                </form>
            </Dialog>

            <Toast ref={toast} />
        </>
    )
}
