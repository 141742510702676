import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import axios from "axios";
import { AddNumber, deleteNumber, getNumber } from '../../../../services/Integrations';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

export default function Integrations() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const [visible, setVisible] = useState(false);
    const [userToken, setUserToken] = useState("");
    const [businesses, setBusinesses] = useState([]);
    const [wabaAccounts, setWabaAccounts] = useState([]);
    const [page, setPage] = useState(1);
    const msgs = useRef(null);
    const [debugData, setDebugData] = useState(null);
    const [wabaId, setWabaId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        document.title = 'DialogEase - ' + t('integrations')
    }, [])


    const debugToken = async () => {
        try {
            const response = await axios.get(
                `https://graph.facebook.com/v21.0/debug_token`,
                {
                    params: {
                        input_token: userToken,
                        access_token: window.FB_TOKEN,
                    },
                }
            );



            // console.log("debugToken", response.data.data)

            if (response.data.data.granular_scopes[1] && response.data.data.granular_scopes[1].target_ids) {
                setWabaId(response.data.data.granular_scopes[1].target_ids[0]);
                let wpid = response.data.data.granular_scopes[1].target_ids[0];
                fetchPhoneNumber(wpid);
            } else {
                toast.current.show({
                    severity: 'error', summary: 'Error', detail: t('you_don_t_have_whatsApp_business'), life: 8000
                });
                setLoading(false);
            }

        } catch (err) {
            // Handle error in case the request fails
            console.error("Error debugging token:", err);
        }
    };

    const fetchPhoneNumber = async (wpid) => {
        try {
            const response = await axios.get(
                `https://graph.facebook.com/v21.0/${wpid}/phone_numbers`,
                {
                    params: {
                        access_token: window.FB_TOKEN,
                    },
                }
            );
            // console.log(response.data.data[0])

            // console.log("fetchPhoneNumber", response.data.data)

            let params = {
                display_phone_number: response.data.data[0].display_phone_number,
                id: response.data.data[0].id
            }

            saveNumber(params)

        } catch (err) {
            console.error('Error fetching phone number:', err);
        }
    };


    function get() {
        getNumber(slug).then((res) => {
            setPhoneNumber(res.data.phone_number)
        });
    }

    useEffect(() => {
        get();
    }, [slug]);

    function saveNumber(params) {
        AddNumber(slug, params).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setLoading(false);
            get();
            // console.log("save")
        });
    }

    const accept = () => {
        deleteNumber(slug).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            get();
        });
    };


    const confirmDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: t('do_you_want_to_delete_this_record'),
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject: () => { },
        });
    };


    useEffect(() => {
        if (userToken) {
            debugToken();
        }
    }, [userToken]);

    const whatsappFooter = (
        <>
            {
                phoneNumber ?
                    <div className='flex gap-1'>
                        <div className='border-1 border-primary w-full rounded-md flex justify-center items-center text-primary font-bold h-[50px]'>{t('connected')}  ({phoneNumber})</div>
                        <Button icon="pi pi-trash" severity='danger' onClick={confirmDelete} />
                    </div>
                    :
                    <FacebookLogin setUserToken={setUserToken} setLoading={setLoading} loading={loading} />
            }
        </>
    );

    return (
        <>
            <Card title={t('integrations')} className='h-full'>
                <div className="grid gap-2">

                    <div className='col'>
                        <Card title={() => <h1 className='text-center'>Whatsapp</h1>} footer={whatsappFooter} header={() => <div className='flex justify-center'> <img alt="whatsapp" className='!w-20 h-20' src="/images/whatsapp.png" /></div>} className="pt-2">
                            <p className="m-0 text-justify ">
                                {t('whatsapp_descriptions')}
                            </p>
                        </Card>
                    </div>

                    <div className="col"></div>
                    <div className="col"></div>

                </div>
            </Card>
            <Toast ref={toast} />
            <ConfirmPopup />
        </>
    )
}

const FacebookLogin = ({ setUserToken, setLoading, loading }) => {
    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "492383890133133",
                cookie: true,
                xfbml: true,
                version: "v21.0", // Version de l'API Graph
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, []);

    const handleLogin = () => {
        setLoading(true);
        window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '492383890133133', feature: 'whatsapp_business_management' });

        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    setUserToken(response.authResponse.accessToken);
                    // setUserToken("EAAGZC0g7dUI0BO4ETyyuBZCdweGZBWy29DwU9YkJpLFp7wSiVypzA6J8w5BSWYcUWcG8fOutzo5NLRnZCqISJoUn5TmUP3TDIOZCit1fKf0u3Qs34U4n2ihBBT81JZBUZC6M4Ot2kGQqZAvS2QBNrNPiNDmejNRTUn8RPX6L90aIIjNlBLWQWtlsACWxvvZB5NF0tl4EmZB98PVkRK70mfLguFRw3W3AZDZD");
                    console.log("Connexion réussie : ", response);
                } else {
                    console.error("Erreur de connexion :", response);
                }
            },
            {
                scope: "business_management, whatsapp_business_management, whatsapp_business_messaging",
            }
        );
    };

    return (
        <div>
            <Button onClick={handleLogin} loading={loading} className='w-full' label='Login with Facebook' />
        </div>
    );
};




