import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { getFAQ, saveFAQ } from '../../services/Admin';

export default function Faq() {
    const { t } = useTranslation()
    return (
        <>
            <div className="flex max-w-7xl justify-between px-1 py-5">
                <h4 className="text-3xl font-bold my-1">{t('faq')}</h4>
            </div>

            <QAComponent />

        </>
    )
}


function QAComponent() {
    const { t } = useTranslation();
    const [qaList, setQaList] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);


    const handleAddQA = () => {
        const maxId = qaList.length > 0 ? Math.max(...qaList.map(item => item.id)) : 0;
        setQaList([...qaList, { id: maxId + 1, question: '', answer: '' }]);
    };

    const handleRemoveQA = (index) => {
        const newQaList = qaList.filter((_, i) => i !== index);
        setQaList(newQaList);
    };

    const handleChange = (index, field, value) => {
        const newQaList = [...qaList];
        newQaList[index][field] = value;
        setQaList(newQaList);
    };

    const handleDeleteAll = () => {
        setQaList([]);
    };

    function save() {

        // Check if any question or answer is empty (after trimming whitespace)
        const isValid = qaList.every(qa => qa.question.trim() !== '' && qa.answer.trim() !== '');

        if (!isValid) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('please_fill_out_all_questions_and_answers'), life: 3000 });
            return;
        }

        setLoading(true)
        let params = {
            faqs: qaList
        }

        saveFAQ(params).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 })
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getFAQ().then(res => {
            if (res.data.faqs) {
                setQaList(res.data.faqs);
                // Check if the QA list is empty
                if (res.data.faqs.length === 0) {
                    setQaList([{ id: 0, question: '', answer: '' }]);
                }
            } else {
                setQaList([{ id: 0, question: '', answer: '' }]);
            }
        });
    }, []);


    return (
        <div className="p-card p-4">
            <div className='flex gap-2 justify-end mb-2'>
                {
                    qaList.length > 0 &&
                    <Button label={t('delete_all')} onClick={handleDeleteAll} severity="danger" />
                }


                <Button icon='pi pi-plus' onClick={handleAddQA} />
            </div>
            <div className='overflow-auto h-[500px]'>
                {qaList.map((qa, index) => (
                    <div key={index} className="mt-4 qna-card p-3">
                        <div className="w-full">
                            <div className='flex justify-between items-end mb-2'>
                                <label htmlFor={"Question" + index} className="block text-900 font-medium mb-2">
                                    {t('question')}
                                </label>
                                <Button icon="pi pi-trash" onClick={() => handleRemoveQA(index)} severity="danger" />
                            </div>
                            <InputTextarea
                                className='w-full'
                                id={"Question" + index}
                                value={qa.question}
                                onChange={(e) => handleChange(index, 'question', e.target.value)}
                                placeholder={t('question')}
                                rows={1}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor={"Answer" + index} className="block text-900 font-medium mb-2">
                                {t('answer')}
                            </label>
                            <InputTextarea
                                className='w-full'
                                id={'Answer' + index}
                                value={qa.answer}
                                onChange={(e) => handleChange(index, 'answer', e.target.value)}
                                placeholder={t('answer')}
                                rows={3}
                            />
                        </div>
                    </div>
                ))}

            </div>

            <div className='mt-5 flex justify-end'>
                <Button label={t('save')} icon="pi pi-check" onClick={save} loading={loading} />
            </div>
            <Toast ref={toast} />
        </div>


    );

}