import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    const location = useLocation();
    const [parentDate, setParentDate] = useState('yesterday');
    return (
        <>
            {/* <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t("activity")}</h4>
            </div> */}
            <div className="gap-5 mb-4 mt-5">
                <div className="w-full flex justify-center gap-2 p-2">
                    <span>
                        <NavLink
                            to="chat-logs"
                            className={({ isActive }) =>
                                `${isActive
                                    ? "bg-primary text-white"
                                    : "text-primary bg-white"
                                }    group flex items-center justify-center gap-x-3 rounded-md p-2 min-w-44 font-semibold leading-6`
                            }
                        >
                            <i className="pi pi-comments"></i>
                            {t("chat_logs")}
                        </NavLink>
                    </span>

                    <span>
                        <NavLink
                            to="leads" state={{ 'date': 120 }}
                            className={({ isActive }) =>
                                `${isActive
                                    ? "bg-primary text-white"
                                    : "text-primary bg-white"
                                }    group flex items-center justify-center gap-x-3 rounded-md p-2 min-w-44 font-semibold leading-6`
                            }
                        >
                            <i className="pi pi-users"></i>
                            {t("leads")}
                        </NavLink>
                    </span>

                </div>
                <div className="activity w-full  mt-4 min-h-[calc(100vh-107px)]">
                    <Outlet context={{ parentDate, setParentDate }} />
                </div>
            </div>
        </>
    );
}
