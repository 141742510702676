import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-2">
                <h4 className="my-1 text-3xl font-bold">{t('documentation')}</h4>
            </div>

            <div className='md:flex gap-5 block'>
                <div className='md:w-20rem w-full p-card p-2 '>
                    <ul className="gap-2  flex justify-center md:block">
                        <li>
                            <NavLink to="getting-started" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                {t('getting_setup')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="create-a-chatbot" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                {t('create_a_chatbot')}
                            </NavLink>
                        </li>




                    </ul>
                </div>
                <div className='w-full'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}
