import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { getUsage } from '../../../services/Statistical';
import { Chart } from 'primereact/chart';
import dayjs from 'dayjs';
import { addLocale } from 'primereact/api';


export default function Usage() {
    const { t, i18n } = useTranslation()
    const [ais, setAis] = useState(0);
    const [tokens, setTokens] = useState(0);
    const [load, setLoad] = useState(true);
    const [locale, setLocal] = useState('en');
    const [dates, setDates] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of the current month
        new Date() // Today's date
    ]);

    const [dataAis, setDataAis] = useState({ tokens: [] });
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [maxChatbots, setMaxChatbots] = useState(0);
    const [maxCreditsUsed, setMaxCreditsUsed] = useState(0);

    const [selected, setSelected] = useState('all');
    const [filters, setFilters] = useState([
        { name: t('deleted_chatbots'), value: 'deleted' },
        { name: t('all_chatbots'), value: 'all' },
    ]);


    function fetchUsage() {
        setLoad(false)
        const params = {
            startDate: dayjs(dates[0]).format('YYYY-MM-DD'),
            endDate: dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD') : dayjs(dates[0]).format('YYYY-MM-DD'),
            filter: selected
        }

        getUsage(params).then((res) => {
            setAis(res.data.chatbotsUsed);
            setTokens(res.data.creditsUsed);
            setData(res.data.history.tokens);
            setLabels(res.data.history.dates);
            setDataAis(res.data.tokensPyAi);
            setMaxChatbots(res.data.maxChatbots);
            setMaxCreditsUsed(res.data.maxCreditsUsed);

            const aiFilters = res.data.ais.map(ai => ({
                name: ai.name,
                value: ai.value
            }));

            setFilters(prevFilters => {
                // Create a Set of existing filter values for quick lookup
                const existingValues = new Set(prevFilters.map(filter => filter.value));

                // Filter out any duplicates from aiFilters
                const newFilters = aiFilters.filter(aiFilter => !existingValues.has(aiFilter.value));

                // Return the updated filters
                return [
                    ...newFilters,
                    ...prevFilters,
                ];
            });
            setLoad(true)
        })

    }

    useEffect(() => {
        fetchUsage();
    }, [dates, selected])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('usage');
    }, [])

    useEffect(() => {

        setLocal(i18n.language)

        addLocale('ar', {
            firstDayOfWeek: 1,
            dayNames: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
            dayNamesShort: ['أح', 'إث', 'ثل', 'أر', 'خ', 'ج', 'س'],
            dayNamesMin: ['أ', 'إ', 'ث', 'أ', 'خ', 'ج', 'س'],
            monthNames: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
            monthNamesShort: ['يناير', 'فبراير', 'مارس', 'أبريل', 'ماي', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
            today: 'اليوم',
            clear: 'مسح',
        });

        addLocale('fr', {
            firstDayOfWeek: 1,
            dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
            dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
            dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
            monthNamesShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'juil', 'aoû', 'sep', 'oct', 'nov', 'déc'],
            today: 'Aujourd\'hui',
            clear: 'Effacer',
        });


    })
    return (
        <>
            <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('usage')}</h4>

                <div className='flex gap-2'>
                    <Dropdown value={selected} onChange={(e) => setSelected(e.value)} options={filters} optionLabel="name"
                        placeholder={t('all_chatbots')} className="w-14rem" />
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" locale={locale} readOnlyInput dateFormat="yy/mm/dd" />
                </div>

            </div>

            <div className='flex gap-3'>
                <div className='p-card p-4 w-full'>
                    <div>
                        <div className="flex gap-1">
                            <div className="font-bold text-2xl lg:text-3xl">{tokens}</div>
                            <div className="mt-auto flex gap-1 font-medium text-md text-zinc-500 lg:text-lg">
                                / {maxCreditsUsed}
                            </div>
                        </div>
                        <div className="text-zinc-600 mt-3">
                            {t('tokens_used')}
                        </div>
                    </div>
                </div>

                <div className='p-card p-4 w-full'>
                    <div>
                        <div className="flex gap-1">
                            <div className="font-bold text-2xl lg:text-3xl">{ais}</div>
                            <div className="mt-auto flex gap-1 font-medium text-md text-zinc-500 lg:text-lg">
                                / {maxChatbots}
                            </div>
                        </div>
                        <div className="text-zinc-600 mt-3">
                            {t('chatbots_used')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid mt-2">
                <div className="col-12 md:col-6 ">
                    <div className='p-card p-4 h-full'>
                        <h4 className="my-1 text-xl font-semibold">{t('usage_history')}</h4>

                        <div className='mt-4'>
                            {
                                load &&
                                <UsageHistory dataD={data} labels={labels} />
                            }
                        </div>

                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className='p-card p-4 h-full'>
                        <h4 className="my-1 text-xl font-semibold">{t('tokens_used_per_chatbot')}</h4>

                        <div className='mt-4'>
                            {
                                load &&
                                <TokensPyAi dataAis={dataAis} />
                            }
                        </div>

                    </div>
                </div>

            </div>


        </>
    )
}


function TokensPyAi({ dataAis }) {
    const { t } = useTranslation()
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});


    function getRandomColor() {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
    }

    function convertToRGBA(rgbColor, opacity = 0.8) {
        // Extract the RGB values from the `rgb` string
        const rgbValues = rgbColor.match(/\d+/g);
        const [r, g, b] = rgbValues;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    const dataAisLength = dataAis.tokens.length;
    const backgroundColors = Array.from({ length: dataAisLength }, () => getRandomColor());
    const hoverBackgroundColors = backgroundColors.map(color => convertToRGBA(color));


    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: dataAis.ais,
            datasets: [
                {
                    data: dataAis.tokens,
                    backgroundColor: backgroundColors,
                    hoverBackgroundColor: hoverBackgroundColors
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (<div className='flex justify-center'>

        <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem" />


    </div>
    )
}


function UsageHistory({ dataD, labels }) {
    const { t } = useTranslation()
    const [chartData, setChartData] = useState({});
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Messages',
                    data: dataD,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--primary-color'),
                    tension: 0.4
                }
            ]
        };
        setChartData(data);
    }, [dataD, labels]);

    return (<>
        {
            dataD.length == 0 ?
                <div>{t('not_tokens_consumed')} </div>
                :
                <Chart type="line" data={chartData} />
        }

    </>
    )
}