import Api from "./Api";
import Cookie from "js-cookie";
import axios from "axios";

export default {
    getCookie() {
        let token = Cookie.get("XSRF-TOKEN");

        if (token) {
            return new Promise(resolve => {
                resolve(token);
            });
        }

        return axios.get("/sanctum/csrf-cookie");
    }
};