import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { getLeads } from '../../../services/Leads';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { ChatTemplate } from '../ChatBot/Activity/ChatLogs';
import { LeadLogWhitId } from '../../../services/ChatAi';
import moment from 'moment-timezone';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';


export default function Leads() {
    const { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [leads, setLeads] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [date, setDate] = useState('last_7_days');
    const [visibleConversation, setVisibleConversation] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentChat, setCurrentChat] = useState({});
    const [slug, setSlug] = useState(null);
    const [customDate, setCustomDate] = useState(null);
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState([
        { field: 'date', header: t('date_leads') },
        { field: 'firstName', header: t('first_name') },
        { field: 'lastName', header: t('last_name') },
        { field: 'phone', header: t('phone') },
        { field: 'email', header: t('email') },
        { field: 'cp', header: t('cp') },
        { field: 'ip', header: t('ip') },
        { field: 'action', header: t('Actions') },
    ]);


    const dates = [
        { name: t('all_time'), value: 'all_time' },
        { name: t('today'), value: 'today' },
        { name: t('yesterday'), value: 'yesterday' },
        { name: t('last_7_days'), value: 'last_7_days' },
        { name: t('last_30_days'), value: 'last_30_days' },
        { name: t('this_month'), value: 'this_month' },
        { name: t('last_month'), value: 'last_month' },
        { name: t('this_year'), value: 'this_year' },
        { name: t("last_year"), value: 'last_year' },
        { name: t('last_24_hours'), value: 'last_24_hours' },
        { name: t('last_48_hours'), value: 'last_48_hours' },
        { name: t('last_7_hours'), value: 'last_7_hours' },
        { name: t('custom_dates'), value: 'custom' },
    ];


    const [selected, setSelected] = useState('all');
    const [filters, setFilters] = useState([
        { name: t('all_chatbots'), value: 'all' },
    ]);


    function fetchLeads(date, ai, page = 1) {
        getLeads(date, customDate, search, ai, page, perPage).then((res) => {

            setColumns([
                { field: 'date', header: t('date_leads') },
                { field: 'firstName', header: t('first_name') },
                { field: 'lastName', header: t('last_name') },
                { field: 'phone', header: t('phone') },
                { field: 'email', header: t('email') },
                { field: 'cp', header: t('cp') },
                { field: 'ip', header: t('ip') },
                { field: 'action', header: t('Actions') },
            ])

            // setPerPage(res.data.per_page);
            setTotal(res.data.total);
            var data = [];
            var leads = res.data.leads.data;

            let allEmailsEmpty = true;
            let allLastNameEmpty = true;
            let allFirstNameEmpty = true;
            let allPhoneEmpty = true;

            leads.forEach(lead => {
                let tab = JSON.parse(lead.data);
                let filteredTab = { ...tab };

                ['firstName', 'lastName', 'email', 'adresse', 'phone'].forEach(key => {
                    delete filteredTab[key];
                });

                data.push({
                    id: lead.id,
                    conversation_id: lead.conversation_id,
                    slug: lead.slug,
                    firstName: tab.firstName || '',
                    lastName: tab.lastName || '',
                    email: tab.email || '',
                    phone: tab.phone || '',
                    adresse: tab.adresse || '',
                    cp: Object.keys(filteredTab).length ? JSON.stringify(filteredTab) : '',
                    ip: lead.ip
                });

                if (tab.email) allEmailsEmpty = false;
                if (tab.firstName) allFirstNameEmpty = false;
                if (tab.lastName) allLastNameEmpty = false;
                if (tab.phone) allPhoneEmpty = false;
            });

            const aiFilters = res.data.ais.map(ai => ({
                name: ai.name,
                value: ai.value
            }));

            setFilters(prevFilters => {
                // Create a Set of existing filter values for quick lookup
                const existingValues = new Set(prevFilters.map(filter => filter.value));

                // Filter out any duplicates from aiFilters
                const newFilters = aiFilters.filter(aiFilter => !existingValues.has(aiFilter.value));

                // Return the updated filters
                return [
                    ...prevFilters,
                    ...newFilters,
                ];
            })

            if (data.length > 0) {
                if (allEmailsEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'email'));
                }
                if (allFirstNameEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'firstName'));
                }

                if (allLastNameEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'lastName'));
                }

                if (allPhoneEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'phone'));
                }
            }

            setLeads(data)

        }).catch(err => {
            if (err.response.status === 404) {
                setLeads([])
                setTotal(0)
            }
        });
    }

    const template = (product) => {
        const cp = product.cp ? JSON.parse(product.cp) : {};
        return (
            <div>
                {
                    Object.keys(cp).map((key, index) => (
                        <p key={index}>
                            <b> {key}</b>: {cp[key]} <br />
                        </p>
                    ))
                }
            </div >
        );
    };


    const handleSelectLead = (id, slug) => {
        setSlug(slug);
        setVisibleConversation(true);
        setMessages([])
        setCurrentChat({})
        LeadLogWhitId(id).then(res => {
            setMessages(res.data.messages);
            setCurrentChat({ url: res.data.url, created_at: res.data.created_at });
        })
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        fetchLeads(date, selected, event.page + 1)
    };

    useEffect(() => {
        fetchLeads(date, selected)
    }, [date, selected, perPage, search, customDate, search])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('leads')
    }, [])

    function customDateBody(data) {
        return moment(data.date).format('DD/MM/YYYY HH:mm');
    }


    const actions = (data) => {

        return (
            <div className='flex gap-1'>
                <Button icon="pi pi-comment" severity="secondary" rounded aria-label="conversation" onClick={() => handleSelectLead(data.conversation_id, data.slug)} />
            </div>
        );
    };

    return (
        <>
            <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('leads')}</h4>

                <div className='flex gap-2 flex-row-reverse'>
                    <InputText value={search} placeholder={t('search')} onChange={(e) => setSearch(e.target.value)} />
                    <Dropdown value={selected} onChange={(e) => setSelected(e.value)} options={filters} optionLabel="name"
                        placeholder={t('all_chatbots')} className="w-14rem" />
                    <Dropdown value={date} onChange={(e) => setDate(e.value)} options={dates} optionLabel="name"
                        placeholder={dates[0].name} />

                    {date == 'custom' &&
                        <Calendar value={customDate} onChange={(e) => setCustomDate(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection dateFormat="yy/mm/dd" />
                    }
                </div>

            </div>

            <div className='p-card'>
                <div>
                    <DataTable ref={dt} value={leads} tableStyle={{ minWidth: '85rem' }} emptyMessage="No Leads found.">
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header}
                                sortable={['firstName', 'lastName', 'phone', 'email', 'ip', 'date'].includes(col.field)}
                                body={col.field === 'action' ? actions : col.field === "date" ? customDateBody : col.field === 'cp' && template} className='max-w-64' />
                        ))}
                    </DataTable>
                    {
                        total > 0 && <div className='flex items-center justify-center'>
                            <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
                            <Dropdown value={perPage} onChange={(e) => setPerPage(e.value)} options={[10, 20, 30, 50, 100]} className="me-3" />
                            <p>{t('pagination_text', { 'first': first + 1, 'end': first + leads.length, 'total': total })}</p>
                        </div>
                    }

                </div >
            </div>

            <Sidebar visible={visibleConversation} position="right" onHide={() => setVisibleConversation(false)} className="w-full md:w-20rem lg:w-30rem">
                <div>
                    <ChatTemplate messages={messages} data={currentChat} toast={toast} slug={slug} />
                </div>
            </Sidebar>
            <Toast ref={toast} />
        </>
    )
}
