import Api from '../services/Api';
import Csrf from '../services/Csrf';



export const getStatistic = async (slug, limit, page, dates) => {
    await Csrf.getCookie();
    return await Api.get(`/statistic/${slug}`, {
        params: {
            page,
            ...{ limit, dates }
        }
    });
}


export const getUsage = async (params) => {
    await Csrf.getCookie();
    return await Api.get(`/usage`, {
        params
    });
}