import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from '../Footer';
import { Avatar } from 'primereact/avatar';
import { fetchChatAi } from '../../../services/ChatAi';


export default function Layout() {
    const [show, setShow] = useState(false);
    const [ais, setAis] = useState([]);
    const [aiActive, setAiActive] = useState({});
    const { slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    function handleClick(newSlug) {


        const currentPath = location.pathname;

        const newPath = currentPath.replace(/dashboard\/chatbot\/[^/]+/, `dashboard/chatbot/${newSlug}`);

        const activeAi = ais.find(ai => ai.slug === newSlug);
        setAiActive(activeAi || {});

        navigate(newPath);
        setShow(false);
    }


    useEffect(() => {
        fetchChatAi().then((response) => {
            const fetchedAis = response.data.ais;
            setAis(fetchedAis);

            const activeAi = fetchedAis.find(ai => ai.slug === slug);
            setAiActive(activeAi || {});
        })
    }, [])

    return (
        <div className='static'>
            <NavBar />
            <div className='max-w-screen-2xl mx-auto mt-1 min-h-screen'>
                <Outlet />

                {aiActive &&
                    <div className='fixed top-[10%] bottom-[80%]  z-40 right-2 flex justify-start items-start flex-row-reverse gap-1'>

                        <Avatar image={aiActive.image} size="xlarge" shape="circle" onClick={() => setShow(true)} className='w-14 h-14 hover:opacity-100 opacity-[80%] bg-white cursor-pointer  border-2 border-primary' />

                        {
                            show &&
                            <div className="relative">
                                <span onClick={() => setShow(false)} className='pi pi-times-circle text-3xl cursor-pointer bg-white p-2 text-red-500 rounded-full absolute top-[-20px]  right-[-20px]'></span>
                                <div className='max-w-80 min-w-64 px-2 py-3 bg-white rounded-md shadow-md'>
                                    <div className=" space-y-3">
                                        {
                                            ais.map((ai, index) => <div key={index}>

                                                {
                                                    aiActive.slug == ai.slug ?
                                                        <div className='flex items-center gap-2 p-2 rounded-md border-primary border-1 cursor-not-allowed bg-primary'>
                                                            <Avatar image={ai.image} shape="circle" onClick={() => setShow(true)} className='!w-10 h-10 cursor-pointer' />
                                                            <span className=' font-bold'>{ai.name}</span>
                                                        </div> :
                                                        <div onClick={() => handleClick(ai.slug)} className='flex items-center gap-2 p-2 rounded-md border-primary border-1 cursor-pointer text-primary  hover:bg-primary hover:text-white'>
                                                            <Avatar image={ai.image} shape="circle" onClick={() => setShow(true)} className='!w-10 h-10 cursor-pointer' />
                                                            <span className=' font-bold'>{ai.name}</span>
                                                        </div>
                                                }

                                            </div>



                                            )
                                        }


                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>
            <Footer />
        </div>
    )
}
