import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const editAbonnementId = async (abonnementId, plan) => {
    await Csrf.getCookie();
    return await Api.post(`/edit-abonnement-id`, {
        abonnementId,
        plan,
    });
}

export const upgradePlan = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/upgrade-plan`, params);
}

export const cancelSubscription = async () => {
    await Csrf.getCookie();
    return await Api.post(`/cancel-subscription`);
}

export const getSubscription = async () => {
    await Csrf.getCookie();
    return await Api.get(`/get-subscription`);
}

export const activeSubscription = async () => {
    await Csrf.getCookie();
    return await Api.post(`/active-subscription`);
}

export const getTransactions = async () => {
    await Csrf.getCookie();
    return await Api.get(`/get-transactions`);
}

export const viewInvoice = async (id) => {
    await Csrf.getCookie();
    return await Api.post(`/view-invoice/${id}`);
}


export const billingDetails = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/billing-details`, params);
}

export const billingEmail = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/billing-email`, params);
}

export const taxIdSave = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/tax-id`, params);
}

export const addExtraMessages = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/add-extra-messages`, params);
}