import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { getAi, updateAiColors } from '../../services/ChatAi';
import { ColorPicker } from 'primereact/colorpicker';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Slider } from "primereact/slider";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';


export default function CustomBot() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const toast = useRef(null);
    const [ai, setAi] = useState({});
    const [visible, setVisible] = useState(false);

    const options = ['ltr', 'rtl'];
    const optionsPosition = [
        { name: t('left'), value: 'start' },
        { name: t('center'), value: 'center' },
        { name: t('right'), value: 'end' },
    ];
    const [bidirectional, setBidirectional] = useState('');

    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [pgSent, setPgSent] = useState('');
    const [pgReceived, setPgReceived] = useState('');
    const [colorSent, setColorSent] = useState('');
    const [colorReceived, setColorReceived] = useState('');
    const [fontSize, setFontSize] = useState();
    const [pgImage, setPgImage] = useState("");
    const [pgImageBack, setPgImageBack] = useState("");
    const [padding, setPadding] = useState();
    const [pgColor, setPgColor] = useState();
    const [checked, setChecked] = useState(false);
    const [loud, setLoud] = useState(false);
    const [position, setPosition] = useState("");


    useEffect(() => {
        setLoud(true);
        getAi(slug).then((res) => {
            setAi(res.data.ai);
            let colors = JSON.parse(res.data.ai.data_colors)
            setPrimaryColor(colors.primaryColor);
            setSecondaryColor(colors.secondaryColor);
            setPgSent(colors.pgSent);
            setPgReceived(colors.pgReceived);
            setColorSent(colors.colorSent);
            setColorReceived(colors.colorReceived);
            setFontSize(colors.fontSize);
            setPgImage(colors.pgImage);
            setPgImageBack(colors.pgImage);
            setChecked(colors.checked)
            setPadding(colors.padding)
            setPgColor(colors.pgColor)
            setBidirectional(colors.dir)
            setPosition(colors.position)
            setLoud(false)
        })
    }, [slug])

    function handleSubmit() {
        let params = {
            data_colors: {
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
                pgSent: pgSent,
                pgReceived: pgReceived,
                colorSent: colorSent,
                colorReceived: colorReceived,
                fontSize: fontSize,
                pgImage: pgImage,
                checked: checked,
                padding: padding,
                pgColor: pgColor,
                dir: bidirectional,
                position: position
            }
        };


        updateAiColors(slug, params).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
        }).catch(err => {
            if (err.response && err.response.status === 422) {
                const errors = err.response.data.errors;
                for (const field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        errors[field].forEach((message) => {
                            toast.current.show({
                                severity: 'error',
                                detail: `${message}`
                            });
                        });
                    }
                }
            }

            if (err.response && err.response.status === 400 || err.response.status === 404) {
                const error = err.response.data.error;
                toast.current.show({
                    severity: 'error',
                    detail: `${error}`
                });
            }
        })
    }


    const onTemplateSelect = async (e) => {
        let file = e.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = function () {
            const base64data = reader.result;
            setPgImage(base64data);
        };
    };

    const customBase64Uploader = async (event) => {
        setPgImage(pgImageBack)
        event.options.clear();
    };

    function changeFontSize(e) {
        let value = e.target.value
        if (value > 20) {
            value = 20;
        } else if (value < 8) {
            value = 8;
        }
        setFontSize(value);
    }


    function changePadding(e) {
        let value = e.target.value
        if (value > 30) {
            value = 30;
        } else if (value < 8) {
            value = 8;
        }
        setPadding(value);
    }

    return (<>
        {loud ? <div className='h-15rem flex justify-center items-center'  >
            <i className="pi pi-spin pi-spinner text-primary" style={{ fontSize: '3rem' }}></i>
        </div> : <>


            <div className='grid'>
                <div className='col-12 xl:col-8'>
                    <div className=''>

                        <h4 className="mb-3 text-lg font-bold">{t('texts_settings')}</h4>

                        <div className='grid col'>

                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-hex" className="font-bold block mb-2">
                                    {t('header_text')}
                                </label>
                                <ColorPicker inputId="cp-hex" format="hex" value={secondaryColor} onChange={(e) => setSecondaryColor(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{secondaryColor}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={secondaryColor} onChange={(e) => setSecondaryColor(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>


                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-3" className="font-bold block mb-2">
                                    {t('sent_messages')}
                                </label>
                                <ColorPicker inputId="cp-3" format="hex" value={colorSent} onChange={(e) => setColorSent(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{colorSent}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={colorSent} onChange={(e) => setColorSent(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>

                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-4" className="font-bold block mb-2">
                                    {t('received_messages')}
                                </label>
                                <ColorPicker inputId="cp-4" format="hex" value={colorReceived} onChange={(e) => setColorReceived(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{colorReceived}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={colorReceived} onChange={(e) => setColorReceived(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>

                            <div className="flex flex-column align-items-center col-6">
                                <label htmlFor="cp-6" className="font-bold block mb-2">
                                    {t('received_messages')}
                                </label>
                                <InputText value={fontSize} onChange={changeFontSize} keyfilter="pint" className="w-full" />
                                <Slider value={fontSize} onChange={(e) => setFontSize(e.value)} className="w-full" min={8} max={20} />
                            </div>

                            <div className="flex flex-column align-items-center col-6">
                                <label htmlFor="cp-6" className="font-bold block mb-2">
                                    {t('padding')}
                                </label>
                                <InputText value={padding} onChange={changePadding} keyfilter="pint" className="w-full" />
                                <Slider value={padding} onChange={(e) => setPadding(e.value)} className="w-full" min={8} max={30} />
                            </div>

                        </div>

                    </div>

                    <div className=''>

                        <h4 className="my-3 text-lg font-bold">{t('backgrounds')}</h4>

                        <div className='grid col'>

                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-1" className="font-bold block mb-2">
                                    {t('background_header')}
                                </label>
                                <ColorPicker inputId="cp-1" format="hex" value={primaryColor} onChange={(e) => setPrimaryColor(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{primaryColor}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={primaryColor} onChange={(e) => setPrimaryColor(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>

                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-2" className="font-bold block mb-2">
                                    {t('sent_messages')}
                                </label>
                                <ColorPicker inputId="cp-2" format="hex" value={pgSent} onChange={(e) => setPgSent(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{pgSent}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={pgSent} onChange={(e) => setPgSent(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>

                            <div className="flex flex-column align-items-center col-4">
                                <label htmlFor="cp-5" className="font-bold block mb-2">
                                    {t('received_messages')}
                                </label>
                                <ColorPicker inputId="cp-5" format="hex" value={pgReceived} onChange={(e) => setPgReceived(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{pgReceived}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={pgReceived} onChange={(e) => setPgReceived(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>

                        </div>
                    </div>


                    <div className=''>

                        <h4 className="my-3 text-lg font-bold">{t('background_conversation')}</h4>

                        <div className='grid col'>


                            <div className="flex flex-column align-items-center col-5">
                                <label htmlFor="cp-7" className="font-bold block mb-2">
                                    {t('image')} (400/630px)
                                </label>

                                {/* <Button label={t('choose_image')} icon="pi pi-upload" onClick={() => setVisible(true)} /> */}

                                <FileUpload mode="basic" name="demo[]" accept="image/*" chooseLabel={t('choose_image')} customUpload uploadHandler={customBase64Uploader} onSelect={onTemplateSelect} />
                                {/* 
                                <div className='mt-4'>

                                    <div className="grid">
                                        <div className="col-4 col-offset-4">
                                            <Button icon="pi pi-arrow-circle-up" severity="secondary" text={position !== 'top'} raised onClick={e => setPosition('top')} />
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="col-4">
                                            <Button icon="pi pi-arrow-circle-left" severity="secondary" text={position !== 'left'} raised onClick={e => setPosition('left')} />
                                        </div>
                                        <div className="col-4">
                                            <Button icon="pi pi-arrows-alt" severity="secondary" text={position !== 'center'} raised onClick={e => setPosition('center')} />
                                        </div>
                                        <div className="col-4">
                                            <Button icon="pi pi-arrow-circle-right" severity="secondary" text={position !== 'right'} raised onClick={e => setPosition('right')} />
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="col-4 col-offset-4">
                                            <Button icon="pi pi-arrow-circle-down" severity="secondary" text={position !== 'bottom'} raised onClick={e => setPosition('bottom')} />
                                        </div>
                                    </div>

                                </div> */}


                            </div>


                            <div className="flex flex-column align-items-center col-7">
                                <div className="flex  items-center mb-2">
                                    <Checkbox inputId="ingredient1" onChange={e => setChecked(e.checked)} checked={checked} />
                                    <label htmlFor="ingredient1" className=" font-bold block  ml-2">{t('use_color')}</label>
                                </div>

                                <ColorPicker inputId="ingredient1" format="hex" value={pgColor} onChange={(e) => setPgColor(e.value)} className="mb-3" />
                                <Inplace closable>
                                    <InplaceDisplay>{pgColor}</InplaceDisplay>
                                    <InplaceContent>
                                        <InputText keyfilter="hex" value={pgColor} onChange={(e) => setPgColor(e.target.value)} />
                                    </InplaceContent>
                                </Inplace>
                            </div>


                        </div>
                    </div>

                    <div className='flex col'>

                        <div className='col-6'>

                            <h4 className="my-3 text-lg font-bold">{t('bidirectional')}</h4>
                            <div className='grid col'>
                                <SelectButton value={bidirectional} onChange={(e) => setBidirectional(e.value)} options={options} />
                            </div>
                        </div>

                        <div className='col-6'>
                            <h4 className="my-3 text-lg font-bold">{t('position_bubble')}</h4>

                            <div className='grid col'>
                                <SelectButton value={position} onChange={(e) => setPosition(e.value)} optionLabel="name" options={optionsPosition} />
                            </div>
                        </div>
                    </div>


                    <div className='col-12 flex justify-end items-end'>
                        <Button label={t('save')} icon="pi pi-check" onClick={handleSubmit} />
                    </div>


                </div>

                <div className='col xl:col-4 pr-1'>
                    <div>
                        <ChatApp name={ai.display_name ?? ai.name} image={ai.image} colors={{
                            "dir": bidirectional,
                            "position": position,
                            "checked": checked, "pgColor": pgColor, "padding": padding, "primaryColor": primaryColor, "secondaryColor": secondaryColor, "pgSent": pgSent, "pgReceived": pgReceived, "colorSent": colorSent, "colorReceived": colorReceived, "fontSize": fontSize, "pgImage": pgImage
                        }} />
                    </div>

                    <div className='mt-2 flex' style={{ justifyContent: position }}>
                        <Avatar image={ai.image} size="xlarge" shape="circle" className='h-14 w-14 border-2 border-primary' />
                    </div>

                </div>
            </div>
            <Toast ref={toast} />


            <Dialog header={t('profile_picture')} visible={visible} onHide={() => { if (!visible) return; setVisible(false) }}>
                <AppAvatar preview={pgImage} setPreview={setPgImage} />
                <div className='flex justify-end mt-2'>
                    <Button icon="pi pi-check" label={t('save')} />
                </div>
            </Dialog>

        </>}
    </>)
}





function ChatApp({ colors, name, image }) {
    const [messages, setMessages] = useState([
        { role: 'user', content: 'What happened last night?' },
        { role: 'bot', content: 'You were drunk.' },
        { role: 'user', content: 'No I wasn\'t.' },
        { role: 'bot', content: 'You were hugging an old man with a beard screaming "DUMBLEDORE YOU\'RE ALIVE!"' },
    ]);
    const [message, setMessage] = useState('');
    const messageEl = useRef(null);
    const [typing, setTyping] = useState(false);

    const send = async (e) => {
        e.preventDefault();
        if (message.trim()) {
            setMessages((prevMessages) => [...prevMessages, { role: 'user', content: message }]);
            setMessage('');
        }
    };

    const handleChange = (e) => {
        setMessage(e.target.value);
    };


    useEffect(() => {
        const handleScroll = (event) => {
            const { currentTarget: target } = event;
            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
        };

        const currentMessageEl = messageEl.current;
        if (currentMessageEl) {
            currentMessageEl.addEventListener('DOMNodeInserted', handleScroll);
        }

        // Cleanup on component unmount
        return () => {
            if (currentMessageEl) {
                currentMessageEl.removeEventListener('DOMNodeInserted', handleScroll);
            }
        };
    }, []);


    function formatContent(content) {
        // Remove 【number:number†source】 pattern
        content = content.replace(/【\d+:\d+†source】/g, '');

        // Replace new line characters with <br /> tags
        let formattedContent = content.replace(/\n/g, '<br />');

        // Bold text (double asterisks)
        formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

        // Italic text (single asterisks or underscores)
        formattedContent = formattedContent.replace(/(?:\*{1}(.*?)\*{1}|_{1}(.*?)_{1})/g, (match, p1, p2) => {
            return `<i>${p1 || p2}</i>`;
        });

        // Strikethrough text (double tildes)
        formattedContent = formattedContent.replace(/~~(.*?)~~/g, '<del>$1</del>');

        // Links ([link text](URL))
        formattedContent = formattedContent.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a class="a-link" href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

        // Inline code (backticks)
        formattedContent = formattedContent.replace(/`(.*?)`/g, '<code>$1</code>');

        // Sanitize the formatted content to prevent XSS attacks
        return DOMPurify.sanitize(formattedContent, {
            ADD_ATTR: ['target']
        });
    }



    let style = `

 

.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ${colors.dir};
}

.marvel-device .screen {
  text-align: left;
}

.screen-container {
  height: 100%;
}



/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #${colors.primaryColor};
  color: #${colors.secondaryColor};
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
  border-radius: 5px 5px 0 0;
  display:flex;
  align-items: start;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height: 545px;
  position: relative;
 background: ${colors.checked ? '#' + colors.pgColor : 'url(' + colors.pgImage + ')'} ;
  background-size: cover;
  z-index: 0;
  border-radius: 0 0 5px 5px;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 58px);
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.message {
  clear: both;
  line-height: 1.5rem;
  font-size: 15px;
  padding: ${colors.padding}px;
  position: relative;
  margin: 10px 0;
  max-width: 85%;
  word-wrap: break-word;
font-size:${colors.fontSize}px
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}




.message:first-child {
  margin: 16px 0 8px;
}

.message.receivedltr {
  background: #${colors.pgReceived};
  color:#${colors.colorReceived};
  border-radius: 0px 5px 5px 5px;
  float: left;
}



.message.receivedltr:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${colors.pgReceived} transparent transparent;
  top: 0;
  left: -10px;
}

.message.sentltr {
  background: #${colors.pgSent};
  color:#${colors.colorSent};
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sentltr:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${colors.pgSent};
  top: 0;
  right: -10px;
}


.message.receivedrtl {
   background: #${colors.pgReceived};
  color:#${colors.colorReceived};
  border-radius: 5px 0px 5px 5px;
  float: right;
}
.message.receivedrtl:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${colors.pgReceived};
  top: 0;
  right: -10px;
}

.message.sentrtl {
  background: #${colors.pgSent};
  color:#${colors.colorSent};
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.sentrtl:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${colors.pgSent} transparent transparent;
  top: 0;
  left: -10px;
}


/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 98.5%;
 background: #fff;
  padding: 5px;
  margin:2px;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 5px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}




.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: transparent;
  border-radius: 50%;
  color: #${colors.primaryColor};
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}



  `;

    return (
        <>
            <style>{style}</style>
            <div className="page">
                <div className="screen">
                    <div className="screen-container">
                        <div className="chat">
                            <div className="chat-container">
                                <div className="user-bar">
                                    <div className="avatar">
                                        <img src={image} alt="Avatar" />
                                    </div>
                                    <div className="name">
                                        <span>{name}</span>
                                    </div>
                                </div>
                                <div className="conversation">
                                    <div className="conversation-container">
                                        {
                                            messages.map((msg, index) =>
                                                <div key={index} className={`message ${msg.role === 'user' ? 'sent' + colors.dir : 'received' + colors.dir}`}>
                                                    {msg.content}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <form className="conversation-compose" onSubmit={send}>
                                        <input className="input-msg" name="input" placeholder="Type a message" autoComplete="off" autoFocus value={message} onChange={handleChange}></input>
                                        <button className="send">
                                            <div className="circle">
                                                <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" width="30" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="M15.44 1.68c.69-.05 1.47.08 2.13.74.66.67.8 1.45.75 2.14-.03.47-.15 1-.25 1.4l-.09.35a43.7 43.7 0 0 1-3.83 10.67A2.52 2.52 0 0 1 9.7 17l-1.65-3.03a.83.83 0 0 1 .14-1l3.1-3.1a.83.83 0 1 0-1.18-1.17l-3.1 3.1a.83.83 0 0 1-.99.14L2.98 10.3a2.52 2.52 0 0 1 .04-4.45 43.7 43.7 0 0 1 11.02-3.9c.4-.1.92-.23 1.4-.26Z"></path></svg>
                                            </div>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}



const AppAvatar = ({ preview, setPreview }) => {
    const { t } = useTranslation()
    const [src] = useState('');
    const toast = useRef(null);

    const onClose = () => {
        setPreview(null);
    };

    const onCrop = (newPreview) => {
        setPreview(newPreview);
    };

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 1048576) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('file_is_too_big'), life: 3000 });
            elem.target.value = '';
        }
    };

    return (
        <div className='flex justify-center' >
            <Avatar
                width={300}
                imageWidth={300}
                height={300}
                label={t('choose_a_file')}
                imageHeight={300}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={src}
            />
            {/* <div>

                {preview && <img src={preview} alt="Preview" />}
            </div> */}
            <Toast ref={toast} />
        </div>
    );
};