import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Link, NavLink, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { addWebsite, deleteFileScrape, editChatAi, editQA, getQA, getWebsite, uploadFileAi, uploadFileSource } from '../../../services/ChatAi';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import dayjs from 'dayjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


export default function Sources() {
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab') || 'files';
    const { t } = useTranslation()
    const { slug } = useParams();
    const toast = useRef(null);
    const [loud, setLoud] = useState(false);
    const [ids, setIds] = useState([]);
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);

    function edit() {
        editChatAi(slug).then((res) => {
            setFiles(res.data.files);
            setIds(prevIds => {
                const newIds = res.data.files.map(file => file.file_id);
                const uniqueIds = newIds.filter(id => !prevIds.includes(id));
                return [...prevIds, ...uniqueIds];
            });
            setLoud(false)
        })
    }


    function save() {
        fileUploadRef.current.upload();
    }


    useEffect(() => {
        if (slug) {
            setLoud(true)
            edit()
        }
    }, [slug])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('sources')
    }, [])

    return (
        <>
            <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('sources')}</h4>
            </div>
            <div className="md:flex gap-5 block ">
                <Sidebar />

                <div className="activity max-md:w-full min-h-[calc(100vh-218px)]">

                    <div className='h-full'>
                        {
                            tab == 'files' &&
                            <div>
                                {
                                    loud ?
                                        <div className='h-15rem flex justify-center items-center'  >
                                            <i className="pi pi-spin pi-spinner text-primary" style={{ fontSize: '3rem' }}></i>
                                        </div> :
                                        <div>
                                            <div className='h-full'>
                                                <div className={`w-full`}>
                                                    <Card title={t('files')}>
                                                        <TemplateDemo ids={ids} setIds={setIds} dFiles={files} setFiles={setFiles} fEdit={edit} fileUploadRef={fileUploadRef} setLoading={setLoading} />

                                                        <div className='mt-5 flex justify-end'>
                                                                <Button label={t('save')} icon="pi pi-check" className='min-w-32' onClick={save} loading={loading} />
                                                        </div>

                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        }

                        {
                            tab == 'qna' &&
                            <div className="w-full h-full">
                                <Card title={t('qna')} className='h-full'>
                                    <QAComponent toast={toast} />
                                </Card>
                            </div>
                        }

                        {
                            tab == 'website' &&
                            <div className="w-full h-full">
                                <Card title={t('website')} className='h-full'>
                                    <Website toast={toast} />
                                </Card>
                            </div>
                        }

                    </div>

                </div>
            </div>
            <Toast ref={toast} />
        </>
    )
}


function TemplateDemo({ setIds, dFiles, setFiles, ids, fEdit, fileUploadRef, setLoading }) {
    const { t } = useTranslation()
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const { slug } = useParams();



    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;

        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;

        });

        setTotalSize(_totalSize);

    };

    function getImage(type) {
        switch (type) {
            case 'application/pdf':
                return '/images/pdf.png';
            case 'text/plain':
                return '/images/txt.png';
            case 'application/msword':
                return '/images/doc.png';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return '/images/docx.png';
            default:
                return '/images/x.png';
        }
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);

        callback();
    };

    const onTemplateClear = () => {

    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={getImage(file.type)} />
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ltr:ml-auto rtl:mr-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };



    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="mt-5 text-center">
                    {t('drag_drop_files_here')}
                </span>
                <span style={{ fontSize: '0.9em', color: 'var(--text-color-secondary)' }} className="mb-5 text-center">
                    {t('supported_file_types')}
                </span>
            </div>
        );
    };

    const invoiceUploadHandler = async ({ files }) => {
        setLoading(true)
        var id = [];
        await uploadFileAi(files).then((response) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 })
            setIds(response.data.ids)
            id = response.data.ids
        })
        if (dFiles) {
            setIds(prevIds => [
                ...prevIds,
                ...dFiles.map(file => file.file_id)
            ]);

            dFiles.map(file => {
                id.push(file.file_id)
            })

        }
        Source(id);

    };


    const Source = async (id) => {

        let params = {
            ids: id,
            _method: 'PUT',
        }


        await uploadFileSource(slug, params).then((response) => {
            fEdit();
            setLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 })
            fileUploadRef.current.clear();
        })
    }

    const chooseOptions = { label: t('choose'), iconOnly: false, className: 'p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content={t('choose')} position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content={t('clear')} position="bottom" />

            <FileUpload ref={fileUploadRef} name="files[]" customUpload={true} uploadHandler={invoiceUploadHandler} multiple accept=".pdf, .doc, .docx, .txt" maxFileSize={10000000}
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

            <ItemDefault files={dFiles} setFiles={setFiles} setIds={setIds} />

        </div>
    )
}



function ItemDefault({ files, setFiles, setIds }) {

    const getImage = (name) => {
        const fileName = name.toLowerCase();

        if (fileName.endsWith('.pdf')) {
            return '/images/pdf.png';
        } else if (fileName.endsWith('.txt')) {
            return '/images/txt.png';
        } else if (fileName.endsWith('.doc')) {
            return '/images/doc.png';
        } else if (fileName.endsWith('.docx')) {
            return '/images/docx.png';
        } else {
            return '/images/x.png';
        }
    };

    const formatSize = (bytes) => {
        const kb = (bytes / 1024).toFixed(2);
        return `${kb} KB`;
    }

    const removeFile = (index) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index);
            const fileToRemove = prevFiles[index];
            const idToRemove = fileToRemove?.file_id;
            setIds(prevIds => prevIds.filter(id => id !== idToRemove));
            return updatedFiles;
        });

    };


    return (
        <div className='mt-4 mx-9'>
            {files && files.map((file, index) => (
                <div key={file.name} className="flex items-center flex-wrap mt-4">
                    <div className="flex items-center" style={{ width: '40%' }}>
                        <img alt={file.name} role="presentation" src={getImage(file.name)} />
                        <span className="flex flex-col text-left ml-3">
                            {file.name}
                        </span>
                    </div>
                    <Tag value={formatSize(file.bytes)} severity="success" className="px-3 py-2" />
                    <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-outlined p-button-rounded p-button-danger ltr:ml-auto rtl:mr-auto"
                        onClick={() => removeFile(index)}
                    />
                </div>
            ))}
        </div>
    );
};


function Sidebar() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab') || 'files';
    return <>


        <div className="md:w-16rem w-full p-card p-2">
            <ul className="flex gap-2 md:space-y-3 justify-center md:block">
                <li>
                    <NavLink
                        to="?tab=files"
                        className={
                            `${tab == 'files'
                                ? "text-primary bg-gray-200 "
                                : "text-gray-900"
                            }   group flex items-center gap-x-3 w-full rounded-md p-2 text-sm font-semibold leading-6`
                        }
                    >
                        <i className="pi pi-file"></i>
                        {t("files")}
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="?tab=qna"
                        className={
                            `${tab == 'qna'
                                ? "text-primary bg-gray-200 "
                                : "text-gray-900"
                            }    group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`
                        }
                    >
                        <i className="pi pi-question-circle"></i>
                        {t("qna")}
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to="?tab=website"
                        className={
                            `${tab == 'website'
                                ? "text-primary bg-gray-200 "
                                : "text-gray-900"
                            }    group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`
                        }
                    >
                        <i className="pi pi-globe"></i>
                        {t("website")}
                    </NavLink>
                </li>

            </ul>
        </div>

    </>
}


function QAComponent({ toast }) {
    const { t } = useTranslation();
    const { slug } = useParams()
    const [qaList, setQaList] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleAddQA = () => {
        setQaList([...qaList, { question: '', answer: '' }]);
    };

    const handleRemoveQA = (index) => {
        const newQaList = qaList.filter((_, i) => i !== index);
        setQaList(newQaList);
    };

    const handleChange = (index, field, value) => {
        const newQaList = [...qaList];
        newQaList[index][field] = value;
        setQaList(newQaList);
    };

    const handleDeleteAll = () => {
        setQaList([]);
    };

    function save() {

        // Check if any question or answer is empty (after trimming whitespace)
        const isValid = qaList.every(qa => qa.question.trim() !== '' && qa.answer.trim() !== '');

        if (!isValid) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('please_fill_out_all_questions_and_answers'), life: 3000 });
            return;
        }

        setLoading(true)
        let params = {
            qa: qaList
        }

        editQA(slug, params).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 })
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getQA(slug).then(res => {
            if (res.data.qa) {
                setQaList(res.data.qa);
                // Check if the QA list is empty
                if (res.data.qa.length === 0) {
                    setQaList([{ question: '', answer: '' }]);
                }
            } else {
                setQaList([{ question: '', answer: '' }]);
            }
        });
    }, [slug]);


    return (
        <div className="qa-component">
            <div className='flex gap-2 justify-end mb-2'>
                {
                    qaList.length > 0 &&
                    <Button label={t('delete_all')} onClick={handleDeleteAll} severity="danger" />
                }


                <Button icon='pi pi-plus' onClick={handleAddQA} />
            </div>
            <div className='overflow-auto h-[500px]'>
                {qaList.map((qa, index) => (
                    <div key={index} className="mt-4 qna-card p-3">
                        <div className="w-full">
                            <div className='flex justify-between items-end mb-2'>
                                <label htmlFor={"Question" + index} className="block text-900 font-medium mb-2">
                                    {t('question')}
                                </label>
                                <Button icon="pi pi-trash" onClick={() => handleRemoveQA(index)} severity="danger" />
                            </div>
                            <InputTextarea
                                className='w-full'
                                id={"Question" + index}
                                value={qa.question}
                                onChange={(e) => handleChange(index, 'question', e.target.value)}
                                placeholder={t('question')}
                                rows={1}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor={"Answer" + index} className="block text-900 font-medium mb-2">
                                {t('answer')}
                            </label>
                            <InputTextarea
                                className='w-full'
                                id={'Answer' + index}
                                value={qa.answer}
                                onChange={(e) => handleChange(index, 'answer', e.target.value)}
                                placeholder={t('answer')}
                                rows={3}
                            />
                        </div>
                    </div>
                ))}

            </div>

            <div className='mt-5 flex justify-end'>
                <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={save} loading={loading} />
            </div>

        </div>


    );

}

function Website({ toast }) {
    const { t } = useTranslation();
    const { slug } = useParams();
    const [website, setWebsite] = useState('');
    const [websites, setWebsites] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [type, setType] = useState('single_page');
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);

    function fetchWebsites(page = 1) {
        getWebsite(slug, page).then(res => {
            setWebsites(res.data.websites);
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
            setFiles(res.data.files);

        }).finally(() => { setRefresh(false); });
    }


    function save() {
        setValidationErrors({});
        setLoading(true);
        let params = {
            website: website,
            type: type
        }

        addWebsite(slug, params).then(res => {
            fetchWebsites();
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setVisible(false);
            setType('single_page');
            setWebsite('');


        }).catch(error => {
            if (error.response && error.response.status === 422) {
                setValidationErrors(error.response.data.errors);
            }

            if (error.response && error.response.status === 403) {
                toast.current.show({ severity: 'warn', summary: "Warning", detail: error.response.data.message, life: 3000, });
            }

        }).finally(() => setLoading(false))
    }


    useEffect(() => {
        fetchWebsites();
    }, [slug]);



    const onPageChange = (event) => {
        setFirst(event.first);
        fetchWebsites(event.page + 1)
    };

    function fRefresh() {
        setRefresh(true);
        setFirst(1);
        fetchWebsites();
    }


    const LinkBody = (data) => {
        return (
            <div className='flex gap-2 items-center  w-[30rem]'>
                {data.pending == 1 ? (
                    <span className="p-2 bg-yellow-500 rounded-full h-2"></span>
                ) : data.status == 1 ? (
                    <span className="p-2 bg-[var(--primary-color)] rounded-full h-2"></span>
                ) : (
                    <span className="p-2 bg-red-500 rounded-full h-2"></span>
                )}
                <Link to={data.link} target='_blank' className="font-medium text-blue-600 dark:text-blue-500 w-full hover:underline truncate">{data.link}</Link>

            </div>
        )
    }

    return <>
        <div className='flex  justify-between'>
            <Button icon="pi pi-refresh" loading={refresh} onClick={() => fRefresh()} />
            <Button label={t('add_new_website')} onClick={() => setVisible(true)} />
        </div>

        <div className='mt-2'>
            <DataTable value={websites} tableStyle={{ minWidth: '50rem' }} scrollable scrollHeight="75vh">
                <Column field="link" header="" body={LinkBody}></Column>
                <Column field="created_at" header={t('created_at')} body={(data) => dayjs(data.created_at).format('YYYY-MM-DD HH:mm')}></Column>
                <Column field="updated_at" header={t('refresh_at')} body={(data) => dayjs(data.updated_at).format('YYYY-MM-DD HH:mm')}></Column>
            </DataTable>
        </div >
        <div className='mt-2'>
            <Paginator first={first} className='dir-ltr' rows={perPage} totalRecords={total} onPageChange={onPageChange} />
        </div>

        <FilesScraped files={files} setFiles={setFiles} />


        <Dialog header={t('add_new_website')} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
            <div className='pt-1'>
                <div className='flex gap-2'>
                    <Button label={t('single_page')} onClick={() => setType('single_page')} className='w-full' outlined={type != 'single_page'} />
                    <Button label={t('multiple_pages')} onClick={() => setType('multiple_pages')} className='w-full' outlined={type != 'multiple_pages'} />
                </div>

                <div className='mt-4'>
                    <label htmlFor="name" className="block text-900 font-medium mb-2">{t('website')}</label>
                    <InputText value={website} onChange={(e) => setWebsite(e.target.value)} className='w-full' placeholder='https://example.com' invalid={!!validationErrors.website} />

                    {!!validationErrors.website && (
                        <small className="text-red-500">{validationErrors.website[0]}</small>
                    )}
                    <p className='mt-1'>{t('if_you_want_to_update_the_site_content_add_it_here')} </p>

                </div>


                <div className='flex justify-end mt-4'>
                    <Button label={t('save')} className='min-w-32' onClick={save} loading={loading} />
                </div>

            </div>
        </Dialog>
    </>
}

function FilesScraped({ files, setFiles }) {
    const toast = useRef(null);
    const formatSize = (bytes) => {
        const kb = (bytes / 1024).toFixed(2);
        return `${kb} KB`;
    }

    const removeFile = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept: () => {

                deleteFileScrape(id).then((res) => {

                    files.splice(files.findIndex(f => f.id === id), 1);
                    setFiles([...files]);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
                });


            },
            reject: () => { }
        });

    };



    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {
                files.map((file, index) => (
                    <div key={index} className="mt-4 p-card py-4 px-2">
                        <div className="flex items-center flex-wrap">
                            <div className="flex items-center" style={{ width: '40%' }}>
                                <img role="presentation" src="/images/json.png" alt="JSON icon" />
                                <span className="flex flex-col text-left ml-3">
                                    {file.name}
                                </span>
                            </div>
                            <Tag value={formatSize(file.bytes)} severity="success" className="px-3 py-2" />
                            <Button
                                type="button"
                                icon="pi pi-times"
                                className="p-button-outlined p-button-rounded p-button-danger ltr:ml-auto rtl:mr-auto"
                                onClick={() => removeFile(file.id)}
                            />
                        </div>
                    </div>
                ))
            }
        </>

    );
};
