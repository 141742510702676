import axios from 'axios';
import axiosRetry from 'axios-retry';
import { BASE_URL } from "../configs/configs";

const Api = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true
});

// You can enable retry if needed
// axiosRetry(Api, {
//     retries: 3,
//     retryCondition: error => error.code === 'ECONNABORTED',
//     retryDelay: retryCount => retryCount * 1000
// });

Api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {

            let currentLang = window.location.pathname.split('/')[1];
            currentLang = ['ar', 'fr', 'en'].includes(currentLang) ? currentLang : 'en';

            if (currentLang === 'ar') {
                document.body.dir = 'rtl';
            } else {
                document.body.dir = 'ltr';
            }

            // List of public paths where you don't need to redirect to login
            const publicPaths = [
                `/${currentLang}/faq`,
                `/${currentLang}/login`,
                `/${currentLang}/signup`,
                `/${currentLang}/forgot-your-password`,
            ];

            // Check for dynamic routes like /password/reset/:token/:email
            const isResetPasswordPath = window.location.pathname.startsWith('/password/reset/');

            // If the current path isn't public, redirect to the login page
            if (!publicPaths.includes(window.location.pathname) && !isResetPasswordPath) {
                const currentPath = window.location.pathname;


                // Set a back message in sessionStorage (optional)
                sessionStorage.setItem('backMessage', "Session expired. Please log in again.");

                // Redirect to login while preserving the language in the URL
                window.location.href = `/${currentLang}/login`; // Preserve the language in the redirect URL
            }
        }
        return Promise.reject(error);
    }
);

export default Api;
