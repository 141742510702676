import React, { useEffect, useRef, useState } from 'react'
import { formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import frLocale from '@fullcalendar/core/locales/fr';
import arLocale from '@fullcalendar/core/locales/ar';
import { getAvailableSlots, getBookedSlots, storeBook, unBook, updateBook } from '../../../services/Appointment'
import { useSelector } from 'react-redux'
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { NavLink } from 'react-router-dom'
import { Sidebar } from 'primereact/sidebar';
import { LeadLogWhitId } from '../../../services/ChatAi'
import { ChatTemplate } from './../ChatBot/Activity/ChatLogs';

export default function DemoApp({ duration }) {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [currentEvents, setCurrentEvents] = useState([])
    const userId = useSelector(state => state.auth.user.id)
    const [initEvents, setInitEvents] = useState([])
    const [availableSlots, setAvailableSlots] = useState([])
    const [visible, setVisible] = useState(false);
    const [visibleForm, setVisibleForm] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [language, setLanguage] = useState('en');
    const [errors, setErrors] = useState({ email: null });
    const [visibleConversation, setVisibleConversation] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentChat, setCurrentChat] = useState({});
    const [slug, setSlug] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState({
        name: '',
        start_time: '',
        end_time: '',
        phone: '',
        email: '',
        date: '',
        conversation_id: null,
        slug_ai: null,
    });


    const [selectedDate, setSelectedDate] = useState(null);

    const [data, setData] = useState({
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        comment: "",
        date: "",
        conversation_id: null,
        slug_ai: null,
    })


    function fetchBookedSlots() {
        getBookedSlots(userId).then(res => {
            setInitEvents(res.data.booked_slots)
        })
    }

    useEffect(() => {
        fetchBookedSlots()
    }, [userId])


    useEffect(() => {
        let language = localStorage.getItem('language') || 'en';
        setLanguage(language)
    })

    function handleEventClick(clickInfo) {
        setVisible(true);
        setSelectedEvent({
            ...selectedEvent,
            name: clickInfo.event.title,
            start_time: formatDateTime(clickInfo.event.start),
            end_time: formatDateTime(clickInfo.event.end),
            phone: clickInfo.event.extendedProps.phone || '',
            email: clickInfo.event.extendedProps.email || '',
            date: clickInfo.event.startStr,
            comment: clickInfo.event.extendedProps.comment || '',
            conversation_id: clickInfo.event.extendedProps.conversation_id || null,
            slug_ai: clickInfo.event.extendedProps.slug_ai || null,
        });
    }


    function handleDateSelect(selectInfo) {

        let start;

        if (selectInfo.view.type === 'dayGridMonth') {
            start = selectInfo.startStr;
        } else {
            start = formatDate(selectInfo.start);
        }

        setData({ ...data, date: start })
        getAvailableSlots(userId, start).then(res => {

            let slots = res.data.available_slots[start];

            if (selectInfo.view.type !== 'dayGridMonth') {
                let selectedTime = new Date(selectInfo.start); // This is the selected start time
                let currentSlot;

                // Convert the duration from '00:45:00' format to milliseconds
                let durationArray = duration.split(':');
                let durationMs = (+durationArray[0] * 60 * 60 + +durationArray[1] * 60 + +durationArray[2]) * 1000;

                // Loop through available slots to check if the start time exists and the duration fits
                let isSlotAvailable = slots.some(slot => {
                    let slotStartTime = new Date(slot.start_time); // Convert slot start_time to Date object
                    let slotEndTime = new Date(slot.end_time); // Convert slot end_time to Date object

                    // Calculate the end time of the selected event based on duration
                    let selectedEndTime = new Date(selectedTime.getTime() + durationMs);

                    // Store the current slot being checked
                    currentSlot = slot;

                    // Check if the selected time matches the slot's start time and fits within the slot's end time
                    return selectedTime.getTime() === slotStartTime.getTime() && selectedEndTime <= slotEndTime;
                });

                if (!isSlotAvailable) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No available slots found in the selected date range.', life: 3000 });
                    return;
                } else {
                    setSelectedDate(currentSlot); // Update selected date with the current slot object
                }
            }




            setAvailableSlots(res.data.available_slots[start])
            setVisibleForm(true)
        }).catch(e => {
            if (e.status === 404) {
                setAvailableSlots([]);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: e.response.data.message, life: 3000 });
            }
        })
    }


    function handleEvents(events) {
        setCurrentEvents(events)
    }


    // Function to format date to YYYY-MM-DD
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensures 2 digits
        let day = ('0' + date.getDate()).slice(-2); // Ensures 2 digits
        return `${year}-${month}-${day}`;
    };

    // Function to format date with time as YYYY-MM-DD HH:mm:ss
    const formatDateTime = (date) => {
        let formattedDate = formatDate(date); // Calls the date format function
        let hours = ('0' + date.getHours()).slice(-2); // Ensures 2 digits
        let minutes = ('0' + date.getMinutes()).slice(-2); // Ensures 2 digits
        let seconds = ('0' + date.getSeconds()).slice(-2); // Ensures 2 digits
        return `${formattedDate} ${hours}:${minutes}:${seconds}`;
    };


    function DeleteBook(date) {
        setLoading(true)
        date = new Date(date);



        let params = {
            date: formatDate(date), // Outputs: 2024-10-08
            start_time: formatDateTime(date) // Outputs: 2024-10-08 15:00:00
        };

        unBook(userId, params).then(res => {
            setVisible(false)
            fetchBookedSlots();
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
        }).finally(() => setLoading(false))
    }



    function save() {
        setLoading(true);
        let params = {
            date: data.date,
            start_time: selectedDate.start_time,
            phone: data.phone,
            email: data.email,
            comment: data.comment,
            firstname: data.firstname,
            lastname: data.lastname
        }

        storeBook(userId, params).then(res => {
            fetchBookedSlots()
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setData({
                lastname: "",
                firstname: "",
                email: "",
                phone: "",
                comment: "",
                date: ""
            })
            setVisibleForm(false)
        }).finally(() => setLoading(false))
    }

    function showEdit(data) {
        setIsEditable(true)
        let name = data.name.split(' ')

        setData({
            firstname: name[0] ?? '',
            lastname: name[1] ?? '',
            email: data.email,
            phone: data.phone,
            comment: data.comment,
            start_time: data.start_time,
            date: data.date,
        })
        setVisibleForm(true)
    }

    const handleSelectLead = (data) => {
        setVisibleConversation(true);
        setSlug(data.slug_ai);
        setMessages([])
        setCurrentChat({})
        LeadLogWhitId(data.conversation_id).then(res => {
            setMessages(res.data.messages);
            setCurrentChat({ url: res.data.url, created_at: res.data.created_at });
        })
    };

    function edit() {
        let date = new Date(data.date)
        let params = {
            date: formatDate(date),
            start_time: data.start_time,
            phone: data.phone,
            email: data.email,
            comment: data.comment,
            firstname: data.firstname,
            lastname: data.lastname
        }

        updateBook(userId, params).then(res => {
            fetchBookedSlots()
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setData({
                lastname: "",
                firstname: "",
                email: "",
                phone: "",
                comment: "",
                date: ""
            })
            setVisibleForm(false)
            setVisible(false)
        }).catch(err => {
            if (err.status === 422) {
                if (err.response.data.errors?.email) {
                    setErrors({ email: err.response.data.errors.email[0] })
                }
            }
        }).finally(() => {
            setLoading(false)
            setErrors({ email: null })
        })

    }

    return (
        <div className='demo-app'>
            <div className='demo-app-main'>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }}
                    initialView='timeGridWeek'
                    editable={false}
                    selectable={true}
                    selectMirror={false}
                    dayMaxEvents={true}
                    locales={[frLocale, arLocale]}
                    locale={language}
                    events={initEvents}
                    select={handleDateSelect}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                    allDaySlot={false}
                    // slotDuration="00:60:00"
                    droppable={false}
                    dragScroll={false}
                />
            </div>



            <Dialog header={() => <span className='capitalize' >{selectedEvent.name}</span>} visible={visible} onHide={() => { if (!visible) return; setVisible(false); }}
                style={{ width: '25vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div className=' space-y-5'>
                    <div><b>{t('start_time')}:</b> {selectedEvent.start_time}</div>
                    <div><b>{t('end_time')}:</b> {selectedEvent.end_time}</div>
                    <div className='flex items-center'><b>{t('phone')}: </b>
                        <NavLink to={`tel:${selectedEvent.phone}`} className="font-medium no-underline ml-2 text-primary cursor-pointer hover:underline" target='_blank'>{selectedEvent.phone}</NavLink>
                    </div>
                    <div className='flex items-center'><b>{t('email')}: </b>
                        <NavLink to={`mailto:${selectedEvent.email}`} className="font-medium no-underline ml-2 text-primary cursor-pointer hover:underline" target='_blank'>{selectedEvent.email}</NavLink>
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex gap-2'>
                            <Button icon="pi pi-pen-to-square" severity="info" onClick={() => showEdit(selectedEvent)} />
                            {
                                selectedEvent.conversation_id &&
                                <Button icon="pi pi-comment" severity="secondary" onClick={() => handleSelectLead(selectedEvent)} />
                            }
                        </div>
                        <Button icon="pi pi-trash" severity="danger" loading={loading} onClick={() => DeleteBook(selectedEvent.date)} />
                    </div>
                </div>
            </Dialog >


            <Dialog header={isEditable ? t('edit_appointment') : t('add_new_appointment')} visible={visibleForm} onHide={() => { if (!visibleForm) return; setVisibleForm(false); setIsEditable(false); setData({}) }}
                style={{ width: '25vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div>

                    <div className=''>
                        <label className="block text-sm font-medium mb-2">{t('first_name')}</label>
                        <InputText value={data.firstname} onChange={(e) => setData({ ...data, firstname: e.target.value })} className='w-full ' />
                    </div>

                    <div className='mt-3'>
                        <label className="block text-sm font-medium mb-2">{t('last_name')}</label>
                        <InputText value={data.lastname} onChange={(e) => setData({ ...data, lastname: e.target.value })} className='w-full ' />
                    </div>

                    <div className='mt-3'>
                        <label className="block text-sm font-medium mb-2">{t('start_time')}</label>
                        {
                            isEditable ?
                                <InputText value={data.start_time} disabled className='w-full ' />
                                :
                                <Dropdown value={selectedDate} onChange={(e) => setSelectedDate(e.value)} options={availableSlots} optionLabel="start_time"
                                    placeholder={t('select_a_time')} className="w-full" />
                        }
                    </div>

                    <div className='mt-3'>
                        <label className="block text-sm font-medium mb-2">{t('email')}</label>
                        <InputText value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} invalid={errors.email} className='w-full ' />
                        {errors.email && <small className="text-red-500">{errors.email}</small>}
                    </div>

                    <div className='mt-3'>
                        <label className="block text-sm font-medium mb-2">{t('phone')}</label>
                        <InputText value={data.phone} onChange={(e) => setData({ ...data, phone: e.target.value })} className='w-full ' />
                    </div>

                    <div className='mt-3'>
                        <label className="block text-sm font-medium mb-2">{t('comment')}</label>
                        <InputTextarea value={data.comment} onChange={(e) => setData({ ...data, comment: e.target.value })} rows={2} className='w-full' />
                    </div>

                    <div className='mt-3 flex justify-end'>
                        {
                            isEditable ? <Button label={t('edit')} className='min-w-32' onClick={edit} loading={loading} />
                                :
                                <Button label={t('save')} onClick={save} className='min-w-32' loading={loading} />
                        }
                    </div>

                </div>
            </Dialog>

            <Sidebar visible={visibleConversation} position="right" onHide={() => setVisibleConversation(false)} className="w-full md:w-20rem lg:w-30rem">
                <div>
                    <ChatTemplate messages={messages} data={currentChat} toast={toast} slug={slug} />
                </div>
            </Sidebar>

            <Toast ref={toast} />
        </div >
    )
}

function renderEventContent(eventInfo) {

    return (
        <div className='flex flex-col text-center bg-green-300 text-[#393939] w-full rounded-md'>
            <i>{eventInfo.event.title}</i>
            <b>{eventInfo.timeText}</b>
        </div>
    )
}

