import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

export default function Embed() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const [iframe, setIframe] = useState('');
    const [script, setScript] = useState('');
    const [div, setDev] = useState('');
    const [isCopiedDev, setIsCopiedDev] = useState(false);
    const [isCopiedScript, setIsCopiedScript] = useState(false);
    const [isCopiedScriptPage, setIsCopiedScriptPage] = useState(false);

    useEffect(() => {
        var id = getNonNumericCharacters()
        var text = `<script type="module" crossorigin src="${window.location.protocol}//${window.location.hostname}/assets/iframe.js?key=${slug}&id=${id}"></script>`;
        var div = `<div id="${id}"></div>`;
        var scr = `<script type="module" crossorigin src="${window.location.protocol}//${window.location.hostname}/assets/index.js?key=${slug}"></script>`
        setIframe(text)
        setScript(scr)
        setDev(div)
    }, [slug])


    function getNonNumericCharacters() {
        return slug.replace(/[0-9]/g, '').toLowerCase();
    }


    const copyToClipboard = () => {
        navigator.clipboard.writeText(div).then(() => {
            setIsCopiedDev(true);
            setTimeout(() => setIsCopiedDev(false), 2000);
        });
    };


    const copyToClipboardScript = () => {
        navigator.clipboard.writeText(script).then(() => {
            setIsCopiedScript(true);
            setTimeout(() => setIsCopiedScript(false), 2000);
        });
    };

    const copyToClipboardScriptPage = () => {
        navigator.clipboard.writeText(iframe).then(() => {
            setIsCopiedScriptPage(true);
            setTimeout(() => setIsCopiedScriptPage(false), 2000);
        });
    };


    useEffect(() => {
        document.title = 'DialogEase - ' + t('embed')
    }, [])

    return (
        <div className="card h-full">
            <Card title={t('embed')} className='h-full'>
                <h2 className='mb-2'>{window.location.hostname}</h2>

                <p className="m-0 text-gray-500 mt-5">
                    {t('to_add_a_chat_bubble_to_the_bottom_right_of_your_website_add_this_script_tag_to_your_html')}
                </p>
                <div className='p-4 rounded-lg mt-3 bg-gray-200 relative'>
                    {script}

                    <span className='absolute  ltr:right-3 rtl:left-3 flex justify-center items-center top-0 h-full'>
                        <Button size='small' icon={isCopiedScript ? 'pi pi-check' : 'pi pi-copy'} outlined severity={isCopiedScript ? 'success' : 'secondary'} onClick={copyToClipboardScript} />
                    </span>

                </div>


                <p className="m-0 text-gray-500 mt-8">
                    {t('to_add_the_chatbot_any_where_on_your_website_add_this_div_to_your_html_code')}
                </p>
                <div className='p-4 rounded-lg mt-3 bg-gray-200 relative'>
                    {div}
                    <span className='absolute  ltr:right-3 rtl:left-3 flex justify-center items-center top-0 h-full'>
                        <Button size='small' icon={isCopiedDev ? 'pi pi-check' : 'pi pi-copy'} outlined severity={isCopiedDev ? 'success' : 'secondary'} onClick={copyToClipboard} />
                    </span>
                </div>
                <div className='p-4 rounded-lg mt-2 bg-gray-200 relative'>
                    {iframe}
                    <span className='absolute  ltr:right-3 rtl:left-3 flex justify-center items-center top-0 h-full'>
                        <Button size='small' icon={isCopiedScriptPage ? 'pi pi-check' : 'pi pi-copy'} outlined severity={isCopiedScriptPage ? 'success' : 'secondary'} onClick={copyToClipboardScriptPage} />
                    </span>
                </div>

            </Card>
        </div>
    )
}
