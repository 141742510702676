import { PrimeReactProvider } from 'primereact/api';
import React, { useEffect, useState } from "react";
import RoutesFile from "./routes";
import "primeicons/primeicons.css";
import { fetchUser } from "./Auth/authActions";
import { useDispatch } from 'react-redux';

export default function MyApp() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const loadUser = async () => {
            try {
                await dispatch(fetchUser());
            } catch (error) {
                // console.error("Failed to fetch user:", error);
            } finally {
                setLoading(false);
            }
        };

        loadUser();
    }, [dispatch]);

    if (loading) {
        return;
    }

    return (
        <PrimeReactProvider>
            <RoutesFile />
        </PrimeReactProvider>
    );
}
