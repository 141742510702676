import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const getLeads = async (date, customDate = null, search = null, ai, page, perPage) => {
    await Csrf.getCookie();
    return await Api.get(`/getLeads`, {
        params: {
            page,
            perPage,
            ...{ date, ai, customDate, search }
        }
    });
}