import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Auth/authActions';
import { useTranslation } from 'react-i18next';
import { SelectLang } from '../dashboard/NavBar';


export default function NavBar() {
    const { t } = useTranslation()
    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch();
    const { slug } = useParams();

    const navigate = useNavigate();
    const menuLeft = useRef(null);

    function capitalizeName(name) {
        return name.replace(/\b\w/g, char => char.toUpperCase());
    }

    const items = [
        {
            label: capitalizeName(user.name),
            items: [
                {
                    label: t('dashboard'),
                    icon: 'pi pi-home',
                    command: () => {
                        navigate('/dashboard');
                    }
                },
                {
                    label: t('account_settings'),
                    icon: 'pi pi-cog',
                    command: () => {
                        navigate('/dashboard/settings');
                    }
                },
                {
                    label: t('sign_out'),
                    icon: 'pi pi-sign-out',
                    command: () => {
                        log_out();
                    }
                },

            ]
        },
    ];



    function log_out() {
        dispatch(logout(navigate));
    }


    return (
        <div>
            <nav className="bg-white w-full border-b border-gray-200 ">
                <div className="flex flex-wrap justify-center items-center gap-1 min-[640px]:justify-between p-2 xl:mx-6">

                    <img
                        src="/images/logo.png"
                        className="h-4rem cursor-pointer"
                        alt="Logo"
                        onClick={() => navigate('/')}
                    />

                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">

                        <SelectLang />

                        <Avatar icon="pi pi-user" style={{ height: '45px', width: '45px' }} shape="circle" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" />
                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />

                    </div>
                    <div
                        className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                        id="navbar-sticky" >
                    </div>
                </div>
            </nav>

        </div>
    )
}
