import Api from '../services/Api';
import Csrf from '../services/Csrf';



export const storeWorkingHours = async (id, params) => {
    await Csrf.getCookie();
    return await Api.post(`/working-hours/${id}/post`, params);
}

export const getWorkingHoursStore = async (id) => {
    await Csrf.getCookie();
    return await Api.get(`/working-hours/${id}/results`);
}

export const getBookedSlots = async (id) => {
    await Csrf.getCookie();
    return await Api.get(`/appointments/${id}/book-slots`);
}

export const getAvailableSlots = async (id, $date) => {
    await Csrf.getCookie();
    return await Api.get(`/appointments/${id}/available-slots`, {
        params: {
            start_date: $date,
            end_date: $date,
        }
    });
}

export const storeBook = async (id, params) => {
    await Csrf.getCookie();
    return await Api.post(`/appointments/${id}/book`, params);
}


export const unBook = async (id, params) => {
    await Csrf.getCookie();
    return await Api.post(`/appointments/${id}/unbook`, params);
}

export const updateBook = async (id, params) => {
    await Csrf.getCookie();
    return await Api.put(`/appointments/${id}/book`, params);
}