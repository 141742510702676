import Api from '../services/Api';
import Csrf from '../services/Csrf';


export const AddNumber = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post(`/add-number/${slug}`, params);
}


export const getNumber = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/get-number/${slug}`);
}

export const deleteNumber = async (slug) => {
    await Csrf.getCookie();
    return await Api.delete(`/delete-number/${slug}`);
}
