import Api from '../services/Api';
import Csrf from '../services/Csrf';


export const storeWebhook = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/webhooks`, params);
}

export const getWebhooks = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/webhooks/` + slug);
}

export const updateWebhook = async (id, params) => {
    await Csrf.getCookie();
    return await Api.put(`/webhooks/` + id, params);
}

export const destroyWebhook = async (id) => {
    await Csrf.getCookie();
    return await Api.delete(`/webhooks/` + id);
}

export const FUpdateStatus = async (id) => {
    await Csrf.getCookie();
    return await Api.put(`/webhooks/${id}/status`);
}

export const testWebhook = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/test-webhook`, params);
}