import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { forgotPassword } from "./authActions";
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';




export default function ForgotYourPassword() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const toast = useRef(null);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { lang } = useParams();

    useEffect(() => {
        i18n.changeLanguage(lang || 'en');
    }, [lang]);

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        forgotPassword({ email })
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data, life: 3000 });
                setError(null)
            })
            .catch((err) => {
                setError(err.response.data.message);
            }).finally(() => setLoading(false))
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('Forgot_your_password')
    }, [])

    return (
        <div className="flex align-items-center justify-content-center min-h-screen">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div className="flex flex-col items-center mb-5">
                    <img src="/images/logo.png" alt="hyper" height={10} className="mb-3" />
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="block text-900 font-medium mb-2">{t('email')}</label>
                        <InputText id="email" type="text" placeholder={t('email')} className="w-full"
                            value={email}
                            invalid={error}
                            onChange={(e) => setEmail(e.target.value)} />
                        {error && (
                            <small className="text-red-500">{error}</small>
                        )}
                    </div>

                    <Button label={t('submit')} loading={loading} className="w-full" />
                </form>
            </div>
            <Toast ref={toast} />
        </div>
    );
}
