import React, { useEffect, useState, useRef } from 'react'
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { billingDetails, billingEmail, getTransactions, taxIdSave, viewInvoice } from '../../../services/Subscription';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { useReactToPrint } from "react-to-print";
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';

export default function Billing() {
    const { t } = useTranslation();
    const user = useSelector(state => state.auth.user)
    const [transactions, setTransactions] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [placeholders, setPlaceholders] = useState([]);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({});
    const contentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [loadingView, setLoadingView] = useState(false);
    const [loadingBillingsInfo, setLoadingBillingsInfo] = useState(false);
    const [loadingBillingsEmail, setLoadingBillingsEmail] = useState(false);
    const [loadingTaxId, setLoadingTaxId] = useState(false);
    const promiseResolveRef = useRef(null);
    const toast = useRef(null);
    const [name, setName] = useState("");
    const [oldName, setOldName] = useState("");
    const [country, setCountry] = useState("");
    const [oldCountry, setOldCountry] = useState("");
    const [address, setAddress] = useState("");
    const [oldAddress, setOldAddress] = useState("");
    const [codePostal, setCodePostal] = useState("");
    const [oldCodePostal, setOldCodePostal] = useState("");
    const [city, setCity] = useState("");
    const [oldCity, setOldCity] = useState("");
    const [email, setEmail] = useState("");
    const [oldEmail, setOldEmail] = useState("");
    const [taxType, setTaxType] = useState(1);
    const [taxId, setTaxId] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [load, setLoad] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [oldTax, setOldTax] = useState({});

    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('billing');
    }, [])

    function fetchTransactions() {
        setLoad(true);
        getTransactions().then(res => {
            setName(res.data.billing_infos.name);
            setOldName(res.data.billing_infos.name);
            setCountry(res.data.billing_infos.pays);
            setOldCountry(res.data.billing_infos.pays);
            setAddress(res.data.billing_infos.address);
            setOldAddress(res.data.billing_infos.address);
            setCodePostal(res.data.billing_infos.code_postal);
            setOldCodePostal(res.data.billing_infos.code_postal);
            setCity(res.data.billing_infos.city);
            setOldCity(res.data.billing_infos.city);
            setEmail(res.data.billing_infos.email ?? user.email);
            setOldEmail(res.data.billing_infos.email ?? user.email);
            setTaxType(res.data.billing_infos.tax_id ?? res.data.taxes[0].value);
            setTaxId(res.data.billing_infos.tax_value);
            setTransactions(res.data.transactions);
            setTaxes(res.data.taxes);
            setPlaceholders(res.data.placeholders);
            setPlaceholder(res.data.placeholders[0].value);
            setOldTax({ type: res.data.billing_infos.tax_id ?? res.data.taxes[0].value, value: res.data.billing_infos.tax_value })
            setLoad(false);
        })
    }

    function FormateDate(data) {
        return moment(data.created_at).format('LLL');
    }


    function handleChangeTax(e) {
        setTaxId('')
        setTaxType(e.value)

        const selectedTax = placeholders.find((p) => p.id === e.value);
        setPlaceholder(selectedTax.value);

        if (e.value === oldTax.type) {
            setTaxId(oldTax.value);
        }

    }

    function statusBodyTemplate(data) {
        let severity;
        switch (data.status) {
            case 'COMPLETED':
                severity = 'success';
                break;

            case 'UPGRADE':
                severity = 'info';
                break;

            case 'DOWNGRADE':
                severity = 'warning';
                break;
            case 'CANCELLED':
                severity = 'danger';
                break;
            default:
                severity = 'danger';
                break;
        }
        return <Tag value={data.status} severity={severity}></Tag>;
    }


    function viewInvoiceFn() {
        let errors = {};

        const fields = [
            { key: "name", value: oldName },
            { key: "country", value: oldCountry },
            { key: "address", value: oldAddress },
            { key: "codePostal", value: oldCodePostal },
            { key: "city", value: oldCity },
            { key: "email", value: oldEmail },
            { key: "taxId", value: oldTax.value, condition: oldTax.type !== 1 }
        ];

        fields.forEach(field => {
            if (!field.value && (field.condition === undefined || field.condition)) {
                errors[field.key] = [""];
            }
        });

        setValidationErrors(errors);

        if (Object.keys(errors).length > 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('add_billing_details_first'), life: 3000 });
            return
        }
        setLoadingView(true)
        viewInvoice(data.subscription_id).then(res => {
            window.open(res.data, '_blank')
        }).finally(() => {
            setLoadingView(false);
        })
    }



    const reactToPrintFn = useReactToPrint({
        contentRef,
        documentTitle: data.subscription_id,
        onBeforePrint: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setLoading(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setLoading(false);
        },
    });

    useEffect(() => {
        if (loading && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [loading]);


    useEffect(() => {
        fetchTransactions();
    }, []);


    function saveBillingDetails() {
        setLoadingBillingsInfo(true);
        setValidationErrors({});
        let params = {
            name,
            country,
            address,
            codePostal,
            city
        }

        billingDetails(params)
            .then(res => {
                fetchTransactions();
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error.response.data.errors);
                }
            })
            .finally(() => setLoadingBillingsInfo(false));
    }


    function saveBillingEmail() {
        setLoadingBillingsEmail(true);
        setValidationErrors({});
        let params = {
            email
        }

        billingEmail(params)
            .then(res => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error.response.data.errors);
                }
            })
            .finally(() => setLoadingBillingsEmail(false));
    }

    function saveTaxId() {
        setLoadingTaxId(true);
        setValidationErrors({});
        let params = {
            taxType,
            taxId
        }

        taxIdSave(params)
            .then(res => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error.response.data.errors);
                }
            })
            .finally(() => setLoadingTaxId(false));
    }

    return (
        <>


            <Card title={t('billing_details')} className='mb-4'>
                <div className='space-y-4'>
                    <div>
                        <label className="block text-sm font-medium mb-2">{t('name_of_the_organization')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' invalid={!!validationErrors.name} />

                        }

                        {!!validationErrors.name && (
                            <small className="text-red-500">{validationErrors.name[0]}</small>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">{t('country_or_region')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <Dropdown value={country} onChange={(e) => setCountry(e.value)} options={countries} optionLabel="name" filter
                                    className="w-full" invalid={!!validationErrors.country} />

                        }

                        {!!validationErrors.country && (
                            <small className="text-red-500">{validationErrors.country[0]}</small>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">{t('address')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={address} onChange={(e) => setAddress(e.target.value)} className='w-full' invalid={!!validationErrors.address} />

                        }

                        {!!validationErrors.address && (
                            <small className="text-red-500">{validationErrors.address[0]}</small>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">{t('code_postal')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={codePostal} onChange={(e) => setCodePostal(e.target.value)} className='w-full' invalid={!!validationErrors.codePostal} />

                        }

                        {!!validationErrors.codePostal && (
                            <small className="text-red-500">{validationErrors.codePostal[0]}</small>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">{t('city')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={city} onChange={(e) => setCity(e.target.value)} className='w-full' invalid={!!validationErrors.city} />

                        }

                        {!!validationErrors.city && (
                            <small className="text-red-500">{validationErrors.city[0]}</small>
                        )}
                    </div>

                    <div>
                        <div className='mt-2 flex justify-end'>
                            <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={saveBillingDetails} loading={loadingBillingsInfo} />
                        </div>
                    </div>


                </div>
            </Card>


            <Card title={t('billing_email')} className='mb-4'>
                <div>
                    <label className="block mb-4">{t('billing_email_label')}</label>
                    {
                        load ?
                            <Skeleton height="3rem"></Skeleton>
                            :
                            <InputText value={email} onChange={(e) => setEmail(e.target.value)} className='w-full' invalid={!!validationErrors.email} />

                    }

                    {!!validationErrors.email && (
                        <small className="text-red-500">{validationErrors.email[0]}</small>
                    )}
                </div>


                <div className='mt-4'>
                    <div className='mt-2 flex justify-end'>
                        <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={saveBillingEmail} loading={loadingBillingsEmail} />
                    </div>
                </div>
            </Card>

            <Card title={t('tax_id')} className='mb-4'>
                <p>{t('tax_id_description')}</p>
                <div className='mt-4'>
                    <label className="block text-sm font-medium mb-2">{t('tax_type')}</label>
                    {
                        load ?
                            <Skeleton height="3rem"></Skeleton>
                            :
                            <Dropdown value={taxType} onChange={(e) => handleChangeTax(e)} options={taxes} optionLabel="name"
                                className="w-full" />

                    }

                    {!!validationErrors.taxType && (
                        <small className="text-red-500">{validationErrors.taxType[0]}</small>
                    )}
                </div>

                <div className='mt-4'>
                    <label className="block text-sm font-medium mb-2">{t('id')}</label>
                    {
                        load ?
                            <Skeleton height="3rem"></Skeleton>
                            :
                            <InputText value={taxId} placeholder={placeholder} disabled={taxType == 1} onChange={(e) => setTaxId(e.target.value)} className='w-full' invalid={!!validationErrors.taxId} />
                    }

                    {!!validationErrors.taxId && (
                        <small className="text-red-500">{validationErrors.taxId[0]}</small>
                    )}
                </div>

                <div className='mt-4'>
                    <div className='mt-2 flex justify-end'>
                        <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={saveTaxId} loading={loadingTaxId} />
                    </div>
                </div>

            </Card>


            <Card title={t('billing_history')}>
                <DataTable value={transactions} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="subscription_id" header={t('subscription_id')} body={data => <Button label={data.subscription_id} link onClick={() => { setData(data); viewInvoiceFn(); }} />}></Column>
                    <Column field="amount" header={t('amount')} body={data => '$' + Number(data.amount).toFixed(2)}></Column>
                    <Column field="status" header={t('status')} body={statusBodyTemplate}></Column>
                    <Column field="created_at" header={t('date')} body={FormateDate}></Column>
                </DataTable>
            </Card>

            <Sidebar visible={visible} header={<h1 className='font-bold text-lg'>{data.subscription_id}</h1>} onHide={() => { setVisible(false); }} position="right" className='w-full max-w-xl p-0' >
                <div className=" shadow-lg" ref={contentRef}>
                    <div className="relative overflow-hidden min-h-32 text-center bg-[url('https://preline.co/assets/svg/examples/abstract-bg-1.svg')] bg-no-repeat bg-center">
                        <figure className="absolute inset-x-0 bottom-0 -mb-px">
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                <path fill="currentColor" className="fill-white" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                            </svg>
                        </figure>
                    </div>

                    <div className="relative z-10 -mt-12">

                        <span className="mx-auto flex justify-center items-center size-[62px] rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm">
                            <svg className="size-6" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                                <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </span>

                    </div>


                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 id="hs-ai-offcanvas-label" className="text-lg font-semibold text-gray-800">
                                Invoice from DialogEase
                            </h3>
                            <p className="text-sm text-gray-500">
                                Invoice {data.subscription_id}
                            </p>
                        </div>


                        <div className="mt-5 sm:mt-10 grid grid-cols-2 sm:grid-cols-3 gap-5">
                            <div>
                                <span className="block text-xs uppercase text-gray-500">Amount paid:</span>
                                <span className="block text-sm font-medium text-gray-800 ">${data.amount}</span>
                            </div>


                            <div>
                                <span className="block text-xs uppercase text-gray-500 ">Date paid:</span>
                                <span className="block text-sm font-medium text-gray-800 ">{moment(data.created_at).format('LLL')}</span>
                            </div>

                        </div>


                        <div className="mt-5 sm:mt-10">
                            <h4 className="text-xs font-semibold uppercase text-gray-800 ">Summary</h4>

                            <ul className="mt-3 flex flex-col">
                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                                    <div className="flex items-center justify-between w-full">
                                        <span>Payment to Front</span>
                                        <span>${data.amount}</span>
                                    </div>
                                </li>
                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                                    <div className="flex items-center justify-between w-full">
                                        <span>Tax fee</span>
                                        <span>$0</span>
                                    </div>
                                </li>
                                <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                                    <div className="flex items-center justify-between w-full">
                                        <span>Amount paid</span>
                                        <span>${data.amount}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-5 flex justify-end gap-x-2 printBtn ">

                            <Button icon="pi pi-eye" label="Invoice complet" outlined onClick={viewInvoiceFn} loading={loadingView} />

                            <Button icon="pi pi-print" onClick={() => reactToPrintFn()} loading={loading} label="Print" />

                        </div>

                        <div className="mt-5 sm:mt-10">
                            <p className="text-sm text-gray-500  text-center">If you have any questions, please contact us at <a className="inline-flex items-center gap-x-1.5 text-primary decoration-2 hover:underline focus:outline-none focus:underline font-medium " href="#">contact@dialogease.com</a> or call at <a className="inline-flex items-center gap-x-1.5 text-primary decoration-2 hover:underline focus:outline-none focus:underline font-medium" href="tel:+1898345492">+1 898-34-5492</a></p>
                        </div>
                    </div>
                </div>


            </Sidebar>
            <Toast ref={toast} />
        </>
    )
}


const countries = [
    { "name": "Afghanistan", "value": "AF" },
    { "name": "Albania", "value": "AL" },
    { "name": "Algeria", "value": "DZ" },
    { "name": "Andorra", "value": "AD" },
    { "name": "Angola", "value": "AO" },
    { "name": "Argentina", "value": "AR" },
    { "name": "Armenia", "value": "AM" },
    { "name": "Australia", "value": "AU" },
    { "name": "Austria", "value": "AT" },
    { "name": "Azerbaijan", "value": "AZ" },
    { "name": "Bahamas", "value": "BS" },
    { "name": "Bahrain", "value": "BH" },
    { "name": "Bangladesh", "value": "BD" },
    { "name": "Barbados", "value": "BB" },
    { "name": "Belarus", "value": "BY" },
    { "name": "Belgium", "value": "BE" },
    { "name": "Belize", "value": "BZ" },
    { "name": "Benin", "value": "BJ" },
    { "name": "Bhutan", "value": "BT" },
    { "name": "Bolivia", "value": "BO" },
    { "name": "Bosnia and Herzegovina", "value": "BA" },
    { "name": "Botswana", "value": "BW" },
    { "name": "Brazil", "value": "BR" },
    { "name": "Brunei", "value": "BN" },
    { "name": "Bulgaria", "value": "BG" },
    { "name": "Burkina Faso", "value": "BF" },
    { "name": "Burundi", "value": "BI" },
    { "name": "Cabo Verde", "value": "CV" },
    { "name": "Cambodia", "value": "KH" },
    { "name": "Cameroon", "value": "CM" },
    { "name": "Canada", "value": "CA" },
    { "name": "Central African Republic", "value": "CF" },
    { "name": "Chad", "value": "TD" },
    { "name": "Chile", "value": "CL" },
    { "name": "China", "value": "CN" },
    { "name": "Colombia", "value": "CO" },
    { "name": "Comoros", "value": "KM" },
    { "name": "Congo (Congo-Brazzaville)", "value": "CG" },
    { "name": "Congo (DRC)", "value": "CD" },
    { "name": "Costa Rica", "value": "CR" },
    { "name": "Croatia", "value": "HR" },
    { "name": "Cuba", "value": "CU" },
    { "name": "Cyprus", "value": "CY" },
    { "name": "Czechia (Czech Republic)", "value": "CZ" },
    { "name": "Denmark", "value": "DK" },
    { "name": "Djibouti", "value": "DJ" },
    { "name": "Dominica", "value": "DM" },
    { "name": "Dominican Republic", "value": "DO" },
    { "name": "Ecuador", "value": "EC" },
    { "name": "Egypt", "value": "EG" },
    { "name": "El Salvador", "value": "SV" },
    { "name": "Equatorial Guinea", "value": "GQ" },
    { "name": "Eritrea", "value": "ER" },
    { "name": "Estonia", "value": "EE" },
    { "name": "Eswatini (fmr. Swaziland)", "value": "SZ" },
    { "name": "Ethiopia", "value": "ET" },
    { "name": "Fiji", "value": "FJ" },
    { "name": "Finland", "value": "FI" },
    { "name": "France", "value": "FR" },
    { "name": "Gabon", "value": "GA" },
    { "name": "Gambia", "value": "GM" },
    { "name": "Georgia", "value": "GE" },
    { "name": "Germany", "value": "DE" },
    { "name": "Ghana", "value": "GH" },
    { "name": "Greece", "value": "GR" },
    { "name": "Grenada", "value": "GD" },
    { "name": "Guatemala", "value": "GT" },
    { "name": "Guinea", "value": "GN" },
    { "name": "Guinea-Bissau", "value": "GW" },
    { "name": "Guyana", "value": "GY" },
    { "name": "Haiti", "value": "HT" },
    { "name": "Honduras", "value": "HN" },
    { "name": "Hungary", "value": "HU" },
    { "name": "Iceland", "value": "IS" },
    { "name": "India", "value": "IN" },
    { "name": "Indonesia", "value": "ID" },
    { "name": "Iran", "value": "IR" },
    { "name": "Iraq", "value": "IQ" },
    { "name": "Ireland", "value": "IE" },
    { "name": "Israel", "value": "IL" },
    { "name": "Italy", "value": "IT" },
    { "name": "Ivory Coast", "value": "CI" },
    { "name": "Jamaica", "value": "JM" },
    { "name": "Japan", "value": "JP" },
    { "name": "Jordan", "value": "JO" },
    { "name": "Kazakhstan", "value": "KZ" },
    { "name": "Kenya", "value": "KE" },
    { "name": "Kiribati", "value": "KI" },
    { "name": "Kuwait", "value": "KW" },
    { "name": "Kyrgyzstan", "value": "KG" },
    { "name": "Laos", "value": "LA" },
    { "name": "Latvia", "value": "LV" },
    { "name": "Lebanon", "value": "LB" },
    { "name": "Lesotho", "value": "LS" },
    { "name": "Liberia", "value": "LR" },
    { "name": "Libya", "value": "LY" },
    { "name": "Liechtenstein", "value": "LI" },
    { "name": "Lithuania", "value": "LT" },
    { "name": "Luxembourg", "value": "LU" },
    { "name": "Madagascar", "value": "MG" },
    { "name": "Malawi", "value": "MW" },
    { "name": "Malaysia", "value": "MY" },
    { "name": "Maldives", "value": "MV" },
    { "name": "Mali", "value": "ML" },
    { "name": "Malta", "value": "MT" },
    { "name": "Marshall Islands", "value": "MH" },
    { "name": "Mauritania", "value": "MR" },
    { "name": "Mauritius", "value": "MU" },
    { "name": "Mexico", "value": "MX" },
    { "name": "Micronesia", "value": "FM" },
    { "name": "Moldova", "value": "MD" },
    { "name": "Monaco", "value": "MC" },
    { "name": "Mongolia", "value": "MN" },
    { "name": "Montenegro", "value": "ME" },
    { "name": "Morocco", "value": "MA" },
    { "name": "Mozambique", "value": "MZ" },
    { "name": "Myanmar (Burma)", "value": "MM" },
    { "name": "Namibia", "value": "NA" },
    { "name": "Nauru", "value": "NR" },
    { "name": "Nepal", "value": "NP" },
    { "name": "Netherlands", "value": "NL" },
    { "name": "New Zealand", "value": "NZ" },
    { "name": "Nicaragua", "value": "NI" },
    { "name": "Niger", "value": "NE" },
    { "name": "Nigeria", "value": "NG" },
    { "name": "North Macedonia", "value": "MK" },
    { "name": "Norway", "value": "NO" },
    { "name": "Oman", "value": "OM" },
    { "name": "Pakistan", "value": "PK" },
    { "name": "Palau", "value": "PW" },
    { "name": "Palestine", "value": "PS" },
    { "name": "Panama", "value": "PA" },
    { "name": "Papua New Guinea", "value": "PG" },
    { "name": "Paraguay", "value": "PY" },
    { "name": "Peru", "value": "PE" },
    { "name": "Philippines", "value": "PH" },
    { "name": "Poland", "value": "PL" },
    { "name": "Portugal", "value": "PT" },
    { "name": "Qatar", "value": "QA" },
    { "name": "Romania", "value": "RO" },
    { "name": "Russia", "value": "RU" },
    { "name": "Rwanda", "value": "RW" },
    { "name": "Saint Kitts and Nevis", "value": "KN" },
    { "name": "Saint Lucia", "value": "LC" },
    { "name": "Saint Vincent and the Grenadines", "value": "VC" },
    { "name": "Samoa", "value": "WS" },
    { "name": "San Marino", "value": "SM" },
    { "name": "Sao Tome and Principe", "value": "ST" },
    { "name": "Saudi Arabia", "value": "SA" },
    { "name": "Senegal", "value": "SN" },
    { "name": "Serbia", "value": "RS" },
    { "name": "Seychelles", "value": "SC" },
    { "name": "Sierra Leone", "value": "SL" },
    { "name": "Singapore", "value": "SG" },
    { "name": "Slovakia", "value": "SK" },
    { "name": "Slovenia", "value": "SI" },
    { "name": "Solomon Islands", "value": "SB" },
    { "name": "Somalia", "value": "SO" },
    { "name": "South Africa", "value": "ZA" },
    { "name": "South Korea", "value": "KR" },
    { "name": "South Sudan", "value": "SS" },
    { "name": "Spain", "value": "ES" },
    { "name": "Sri Lanka", "value": "LK" },
    { "name": "Sudan", "value": "SD" },
    { "name": "Suriname", "value": "SR" },
    { "name": "Sweden", "value": "SE" },
    { "name": "Switzerland", "value": "CH" },
    { "name": "Syria", "value": "SY" },
    { "name": "Taiwan", "value": "TW" },
    { "name": "Tajikistan", "value": "TJ" },
    { "name": "Tanzania", "value": "TZ" },
    { "name": "Thailand", "value": "TH" },
    { "name": "Timor-Leste", "value": "TL" },
    { "name": "Togo", "value": "TG" },
    { "name": "Tonga", "value": "TO" },
    { "name": "Trinidad and Tobago", "value": "TT" },
    { "name": "Tunisia", "value": "TN" },
    { "name": "Turkey", "value": "TR" },
    { "name": "Turkmenistan", "value": "TM" },
    { "name": "Tuvalu", "value": "TV" },
    { "name": "Uganda", "value": "UG" },
    { "name": "Ukraine", "value": "UA" },
    { "name": "United Arab Emirates", "value": "AE" },
    { "name": "United Kingdom", "value": "GB" },
    { "name": "United States", "value": "US" },
    { "name": "Uruguay", "value": "UY" },
    { "name": "Uzbekistan", "value": "UZ" },
    { "name": "Vanuatu", "value": "VU" },
    { "name": "Vatican City", "value": "VA" },
    { "name": "Venezuela", "value": "VE" },
    { "name": "Vietnam", "value": "VN" },
    { "name": "Yemen", "value": "YE" },
    { "name": "Zambia", "value": "ZM" },
    { "name": "Zimbabwe", "value": "ZW" }
]