import React, { useEffect, useState, useRef } from 'react'
import { getAi } from '../../../services/ChatAi';
import { Howl } from 'howler';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Toast } from 'primereact/toast';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";



export default function ChatTemplate({ slug, setInstructions, setShow, setOld, preload }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const messageEl = useRef(null);
  const [soundSrc, setSoundSrc] = useState('');
  const sound = new Howl({
    src: [soundSrc],
  });
  const [isSending, setIsSending] = useState(false);
  const toast = useRef(null);

  const [ai, setAi] = useState({});
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [pgSent, setPgSent] = useState('');
  const [pgReceived, setPgReceived] = useState('');
  const [colorSent, setColorSent] = useState('');
  const [colorReceived, setColorReceived] = useState('');
  const [fontSize, setFontSize] = useState();
  const [pgImage, setPgImage] = useState("");
  const [dir, setDir] = useState("");
  const [position, setPosition] = useState("center");
  const [loud, setLoud] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [padding, setPadding] = useState();
  const [pgColor, setPgColor] = useState();
  const [checked, setChecked] = useState(false);
  const [suggestedMessages, setSuggestedMessages] = useState([]);
  const [privacy, setPrivacy] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);
  const [supported, setSupported] = useState(true);
  const [recording, setRecording] = useState(false);
  const [isSound, setIsSound] = useState(false);



  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      setSupported(false);
    }
  }, []);

  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  const handleStart = () => {
    setRecording(true);
    SpeechRecognition.startListening({ continuous: true, language: "fr-FR" });
  };
  const handleStop = () => {
    SpeechRecognition.stopListening();
    setTimeout(() => {
      setRecording(false);
    }, 100);
  };
  const handleRecording = () => {
    if (listening) {
      handleStop();
    } else {
      handleStart();
    }
  }


  useEffect(() => {
    setLoud(true);
    getAi(slug).then((res) => {
      setAi(res.data.ai);
      setSoundSrc(res.data.ai.fileSound)
      setIsSound(res.data.ai.isSound)
      setInstructions(res.data.ai.instructions)
      setSuggestedMessages(res.data.ai.suggested_messages ?? [])
      setPrivacy(res.data.ai.message_privacy)
      setOld(res.data.ai.instructions)
      let colors = JSON.parse(res.data.ai.data_colors)
      setPrimaryColor(colors.primaryColor);
      setSecondaryColor(colors.secondaryColor);
      setPgSent(colors.pgSent);
      setPgReceived(colors.pgReceived);
      setColorSent(colors.colorSent);
      setColorReceived(colors.colorReceived);
      setFontSize(colors.fontSize);
      setPgImage(colors.pgImage);
      setChecked(colors.checked)
      setPadding(colors.padding)
      setPgColor(colors.pgColor)
      setDir(colors.dir)
      setPosition(colors.position)
      setLoud(false)
      setShow(true)

      if (supported) {
        var rec = res.data.ai.isRecording == 1 ? true : false;
        setSupported(rec);
      }

      let idConversation = sessionStorage.getItem(slug);
      if (!idConversation) {
        setMessages([{ role: 'assistant', content: res.data.ai.init_message }])
      }

      setPlaceholder(res.data.ai.placeholder)
    })
  }, [preload, slug])

  function play() {
    if (isSound) {
      sound.play();
    }
  }


  async function Suggest(msg) {
    if (!isSending) {
      let idConversation = sessionStorage.getItem(slug);
      if (!idConversation) {
        await createConversations();
        idConversation = sessionStorage.getItem(slug);
      }

      setMessages((prevMessages) => [...prevMessages, { role: 'user', content: msg }]);
      setTimeout(() => {
        setIsSending(true)
      }, 1000);

      try {
        resetTranscript();
        const response = await axios.post(`/api/sendMessage`, { idConversation, message: msg, role: 'user', keyProp: slug });
        setMessages(response.data.data.message.original.messages)
        setIsSending(false)
        play();

      } catch (error) {

        setIsSending(false)
        if (error.response && error.response.status === 403) {
          toast.current.show({ severity: 'warn', summary: "Warning", detail: error.response.data.message, life: 3000, });

          setMessages((prevMessages) => {
            const lastUserMessageIndex = prevMessages.map(msg => msg.role).lastIndexOf('user');
            if (lastUserMessageIndex === -1) return prevMessages;
            return prevMessages.filter((_, index) => index !== lastUserMessageIndex);
          });

          setTimeout(() => {
            setIsSending(false)
          }, 765);

        }

        // console.error('Error sending message:', error);
      }
    }
  }


  const send = async (e) => {
    e.preventDefault();
    if (message.trim() && !isSending) {
      setIsSending(true);
      const msg = message;
      setMessages((prevMessages) => [...prevMessages, { role: 'user', content: msg }]);
      resetTranscript();
      setMessage('');

      let idConversation = sessionStorage.getItem(slug);
      if (!idConversation) {
        await createConversations();
        idConversation = sessionStorage.getItem(slug);
      }

      try {
        const response = await axios.post(`/api/sendMessage`, { idConversation, message: msg, role: 'user', keyProp: slug });
        setIsSending(false)
        setMessages(response.data.data.message.original.messages);
        play();

      } catch (error) {

        setIsSending(false)

        if (error.response && error.response.status === 403) {
          toast.current.show({ severity: 'warn', summary: "Warning", detail: error.response.data.message, life: 3000, });

          setMessages((prevMessages) => {
            const lastUserMessageIndex = prevMessages.map(msg => msg.role).lastIndexOf('user');
            if (lastUserMessageIndex === -1) return prevMessages;
            return prevMessages.filter((_, index) => index !== lastUserMessageIndex);
          });

          setTimeout(() => {
            setIsSending(false)
          }, 765);

        }
        // console.error('Error sending message:', error);
      }
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };


  const getMessages = async () => {
    const idConversation = sessionStorage.getItem(slug);
    if (idConversation) {
      try {
        const response = await axios.get(`/api/getMessages/${idConversation}`);
        setMessages(response.data.messages);
      } catch (error) {
        if (error.status === 404) {
          refresh();
        }
        // console.error('Error fetching messages:', error);
      }
    }
  };

  const createConversations = async () => {
    try {

      const url = `${window.location.protocol}//${window.location.hostname}`;
      const response = await axios.post(`/api/createConversation`, { "chat_id": slug, url });
      sessionStorage.setItem(slug, response.data.id);
    } catch (error) {

      // if (error.response && error.response.status === 403) {
      //   toast.current.show({ severity: 'warn', summary: "Warning", detail: error.response.data.message, life: 3000, });
      // }

      // console.error('Error creating conversation:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentMessageEl = messageEl.current;
      if (currentMessageEl) {
        currentMessageEl.scroll({ top: currentMessageEl.scrollHeight, behavior: 'smooth' });
      }
    };

    const observer = new MutationObserver(() => {
      handleScroll();
    });

    const currentMessageEl = messageEl.current;

    if (currentMessageEl) {
      observer.observe(currentMessageEl, { childList: true });
    }

    // Cleanup on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [messages]);


  useEffect(() => {
    const idConversation = sessionStorage.getItem(slug);
    if (idConversation) {
      getMessages();
    }
  }, []);

  function refresh() {
    setIsSpinning(true);
    setTimeout(() => {
      sessionStorage.removeItem(slug);
      setMessages([{ role: 'assistant', content: ai.init_message }])
      setMessage('');
      setIsSending(false)
      setIsSpinning(false)
    }, 1000);

  }


  function formatContent(content) {
    // Remove 【number:number†source】 pattern
    content = content.replace(/【\d+:\d+†source】/g, '');

    // Replace new line characters with <br /> tags
    let formattedContent = content.replace(/\n/g, '<br />');

    // Bold text (double asterisks)
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Italic text (single asterisks or underscores)
    formattedContent = formattedContent.replace(/(?:\*{1}(.*?)\*{1}|_{1}(.*?)_{1})/g, (match, p1, p2) => {
      return `<i>${p1 || p2}</i>`;
    });

    // Strikethrough text (double tildes)
    formattedContent = formattedContent.replace(/~~(.*?)~~/g, '<del>$1</del>');

    // Links ([link text](URL))
    formattedContent = formattedContent.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a class="a-link" href="$2" target="_blank">$1</a>');

    // Inline code (backticks)
    formattedContent = formattedContent.replace(/`(.*?)`/g, '<code>$1</code>');

    // Sanitize the formatted content to prevent XSS attacks
    return DOMPurify.sanitize(formattedContent, {
      ADD_ATTR: ['target']
    });
  }


  let style = `

   .a-link {
    color: blue; /* Change the color of the link */
    text-decoration: underline; /* Underline the link */


}

/* Add a hover effect */
.a-link:hover {
    color: darkblue; /* Darken the color on hover */
}

/* Optional: Indicate that the link opens in a new tab */
.a-link::after {
    content: ' ↗'; /* Add a small arrow icon */
    font-size: smaller; /* Make the arrow smaller */
    color: gray; /* Change the arrow color */
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
   direction: ${dir};
}

.marvel-device .screen {
  text-align: left;
}

.screen-container {
  height: 100%;
}



/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
 
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #${primaryColor};
  color: #${secondaryColor};
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
  border-radius: 5px 5px 0 0;
    display:flex;
  align-items: start;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height:  calc(100vh - 272px);
  position: relative;
 background: ${checked ? '#' + pgColor : 'url(' + pgImage + ')'};
  background-size: cover;
  z-index: 0;
  border-radius: 0 0 5px 5px;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - ${suggestedMessages.length ? '124' : '68'}px);
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.message {
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: ${padding}px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
font-size:${fontSize}px
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}




.message:first-child {
  margin: 16px 0 8px;
}


.message.receivedltr {
  background: #${pgReceived};
  color:#${colorReceived};
  border-radius: 0px 5px 5px 5px;
  float: left;
}



.message.receivedltr:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${pgReceived} transparent transparent;
  top: 0;
  left: -10px;
}

.message.sentltr {
  background: #${pgSent};
  color:#${colorSent};
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sentltr:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${pgSent};
  top: 0;
  right: -10px;
}


.message.receivedrtl {
   background: #${pgReceived};
  color:#${colorReceived};
  border-radius: 5px 0px 5px 5px;
  float: right;
}
.message.receivedrtl:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${pgReceived};
  top: 0;
  right: -10px;
}

.message.sentrtl {
  background: #${pgSent};
  color:#${colorSent};
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.sentrtl:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${pgSent} transparent transparent;
  top: 0;
  left: -10px;
}
/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 64px;
  width: 100%;
 background: #fff;
  padding: 8px 15px;
}

.conversation-compose div,
.conversation-compose textarea {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 5px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}




.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #${primaryColor};
  border-radius: 50%;
  color: #${secondaryColor};
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.suggested-messages-container {
  scrollbar-width: thin;              /* Make scrollbar thinner */
  scrollbar-color: #888 #f1f1f1;      /* Thumb color (first) and track color (second) */
}


.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
}
.lds-ellipsis div {
  position: absolute;
top: -10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.Rec{
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
  background: red !important;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.5);
  }
  70% {
      -webkit-box-shadow: 0 0 0 50px rgba(0,0,0, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.5);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 50px rgba(0,0,0, 0);
      box-shadow: 0 0 0 50px rgba(0,0,0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
      box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
  .loaderP2024SSX5 {
   width: 24px;
            height: 24px !important;
            border-radius: 50%;
            position: relative;
            transform:rotate(45deg);
            background: #fff;
 }
 .loaderP2024SSX5:before{
  content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border:12px solid #${primaryColor};
            animation: prixClipFix 2s infinite linear;
            }
 @keyframes prixClipFix {
              0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
              25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
              50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
              75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
              100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
          }

  `;

  return (
    <>
      {loud ? <div className='h-15rem flex justify-center items-center'  >
        <i className="pi pi-spin pi-spinner text-primary" style={{ fontSize: '3rem' }}></i>
      </div> : <>
        <style>{style}</style>
        <div className="page ">
          <div className="screen ">
            <div className="screen-container ">
              <div className="chat ">
                <div className="chat-container !px-3 max-md:!w-dvw md:w-[445px] max-w-[445px] ">
                  <div className="user-bar">

                    <div className="avatar">
                      <img src={ai.image} alt="Avatar" />
                    </div>
                    <div className="name">
                      <span>{ai.display_name ?? ai.name}</span>
                    </div>

                    <div className={` absolute ${dir == 'ltr' ? 'right-3' : 'left-3'} `}>
                      <i className={`pi pi-sync ${isSpinning && 'pi-spin'} cursor-pointer`} onClick={refresh} style={{ fontSize: '1.5rem' }}></i>
                    </div>
                  </div>
                  <div className="conversation" >
                    <div className="conversation-container" ref={messageEl}>
                      {
                        messages.map((msg, i) =>
                          <div key={i} className={`message ${msg.role === 'user' ? 'sent' + dir : 'received' + dir}`}>
                            {/* {msg.content} */}
                            <span dangerouslySetInnerHTML={{ __html: formatContent(msg.content) }} />
                          </div>
                        )
                      }
                      {
                        isSending && <div key='isSending' className={`message ${'received' + dir}`}>
                          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                      }
                    </div>

                    {
                      suggestedMessages.length ?
                        <div className='mx-2 flex overflow-x-auto text-sm items-center pb-2 gap-2 rounded-md mb-1 suggested-messages-container h-[51px]'>
                          {
                            suggestedMessages.map((suggestedMessage, index) =>
                              <span key={index} className='bg-white p-2 rounded-md whitespace-nowrap cursor-pointer' onClick={() => Suggest(suggestedMessage)}>{suggestedMessage}</span>
                            )
                          }
                        </div> : ''
                    }

                    <form className="" onSubmit={send}>
                      <div className='conversation-compose'>
                        <textarea style={{ resize: 'none' }} className="input-msg" name="input" placeholder={placeholder} autoComplete="off" autoFocus value={message} onChange={handleChange}
                          rows={1}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              send(e);
                            }
                          }}
                        ></textarea>
                        {
                          supported && (message.length == 0 || recording) ?
                            <button type='button' className="send" disabled={isSending}
                              onClick={handleRecording}
                            >
                              {
                                listening ?
                                  <div className="circle Rec">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} strokeWidth={1.5} stroke="currentColor" >
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                  </div> :
                                  <>
                                    {isSending ? (
                                      // Loader element
                                      <div className="circle">
                                        <div className="loaderP2024SSX5"></div>
                                      </div>
                                    ) : (
                                      <div className="circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={28}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                                        </svg>
                                      </div>
                                    )}
                                  </>
                              }
                            </button> :
                            <button className="send" disabled={isSending}>
                              <div className="circle">
                                {isSending ? (
                                  // Loader element
                                  <div className="loaderP2024SSX5"></div>
                                ) : (
                                  <svg style={{ ...(dir === "rtl" && { transform: "rotate(260deg)" }), }} xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} width={28} fill="none" viewBox="0 0 20 20">
                                    <path fill="currentColor" d="M15.44 1.68c.69-.05 1.47.08 2.13.74.66.67.8 1.45.75 2.14-.03.47-.15 1-.25 1.4l-.09.35a43.7 43.7 0 0 1-3.83 10.67A2.52 2.52 0 0 1 9.7 17l-1.65-3.03a.83.83 0 0 1 .14-1l3.1-3.1a.83.83 0 1 0-1.18-1.17l-3.1 3.1a.83.83 0 0 1-.99.14L2.98 10.3a2.52 2.52 0 0 1 .04-4.45 43.7 43.7 0 0 1 11.02-3.9c.4-.1.92-.23 1.4-.26Z"></path>
                                  </svg>
                                )}
                              </div>
                            </button>
                        }
                      </div>
                    </form>
                  </div>
                  <div className='h-8 bg-[rgb(250 250 250/1)] flex items-center justify-center text-sm' dangerouslySetInnerHTML={{ __html: privacy }} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </>}
      <Toast ref={toast} />
    </>
  );
}
