import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Footer from "./dashboard/Footer";
import { NavHome } from "./Home";
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getFAQ } from "../services/Admin";

export default function FAQ() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [qaList, setQaList] = useState([]);






    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
    };

    useEffect(() => {
        getFAQ().then(res => {
            setQaList(res.data.faqs);
        });
    }, []);


    return (
        <>
            <div className="min-h-screen">
                <NavHome />

                <section>
                    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">

                        <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                            <h4 className="my-1 text-3xl font-bold">{t('Frequently asked questions')}</h4>
                        </div>
                        <div className=" space-y-2">
                            <Accordion activeIndex={0}>

                                {
                                    qaList.map((item, index) => (
                                        <AccordionTab header={item.question} key={index}>
                                            <p className="m-0">{item.answer}</p>
                                        </AccordionTab>
                                    ))
                                }
                            </Accordion>
                        </div>

                    </div>
                </section>


            </div>
            <Footer />
        </>
    )
}
