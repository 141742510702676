import { Card } from 'primereact/card'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Training() {
    const { t } = useTranslation()
    return (
        <>
            <Card title={t('training')}>

            </Card >
        </>
    )
}
