import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { useNavigate, useParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import axios from 'axios';
import { editChatAi, storeChatAi, uploadFileAi } from '../../services/ChatAi';
import { Dropdown } from 'primereact/dropdown';
import { FModules } from '../../services/Settings';
import { useTranslation } from 'react-i18next';

export default function CreateNewChatbot() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const toast = useRef(null);
    const op = useRef(null);
    const [loud, setLoud] = useState(false);
    const fileInputRef = useRef(null);
    const btnSave = useRef(null);


    const [activeTab, setActiveTab] = useState("Instructions");
    const [text, setText] = useState("");
    const [name, setName] = useState("");
    const [count, setCount] = useState(0);
    const [ids, setIds] = useState([]);
    const [errors, setErrors] = useState({ name: null, instructions: null });
    const [files, setFiles] = useState(null);
    const [image, setImage] = useState("");
    const [selected, setSelected] = useState(null);
    const [modules, setModules] = useState(null);
    const fileUploadRef = useRef(null);
    const [url, setUrl] = useState('');
    const [urls, setUrls] = useState([]);

    function Reset() {
        setText('');
        setSelected(modules[0] ? modules[0] : null);
    }


    function handleChange(event) {
        setSelected(event.value)
        if (event.value.id !== 1) {
            setText(event.value.instruction)
        } else {
            setText('')
        }
    }

    useEffect(() => {
        FModules().then((res) => {
            setModules(res.data.modules);
            if (res.data.modules.length) {
                setSelected(res.data.modules[0])
            }
        })

    }, [])

    const fetchLinks = async () => {
        try {
            if (urls.includes(url)) return true;
            const response = await axios.post('/api/crawl', { url });
            setCount(count + response.data[0].length);
            setUrls(urls.concat(url));
        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (slug) {
            setLoud(true)
            editChatAi(slug).then((res) => {
                setName(res.data.ai.name);
                setText(res.data.ai.instructions);
                setFiles(res.data.files);
                setImage(res.data.ai.image);

                setIds(prevIds => {
                    const newIds = res.data.files.map(file => file.file_id);
                    const uniqueIds = newIds.filter(id => !prevIds.includes(id));
                    return [...prevIds, ...uniqueIds];
                });
                setLoud(false)
            })
        }
    }, [])

    useEffect(() =>
        setCount(text.length)
        , [text])

    const customBase64Uploader = async (event) => {
        event.options.clear();
    };

    const onTemplateSelect = async (e) => {
        let file = e.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = reader.result;
            setImage(base64data);
        };
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('create_new_chatbot')
    }, [])

    return (
        <>
            {
                loud ? <div className='h-15rem flex justify-center items-center'  >
                    <i className="pi pi-spin pi-spinner text-primary" style={{ fontSize: '3rem' }}></i>
                </div> :
                    <div className='mb-4'>

                        <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                            <h4 className="my-1 text-3xl font-bold">{t('create_new_chatbot')}</h4>
                        </div>

                        <div className='mx-2'>
                            <div className='flex gap-2'>
                                <div className="w-full">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">{t('name')}</label>
                                    <InputText id="name" type="text" placeholder={t('name')} className="w-full"
                                        value={name}
                                        invalid={errors.name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.name && (
                                        <small className="text-red-500">{errors.name}</small>
                                    )}
                                </div>


                            </div>

                            <div className='text-center mt-4'>
                                <h1 className='text-3xl font-bold'>{t('data_sources')}</h1>
                                <p className='text-gray-600'>{t('add_your_data_sources_to_train_your_chatbot')} </p>
                            </div>

                            <div className="md:flex gap-4 mt-4">
                                <ul className="flex-column space-y space-y-4 text-sm mb-4 md:mb-0 p-card p-3">
                                    <li>
                                        <Button label={t('instructions')} icon="pi pi-align-left" onClick={() => setActiveTab('Instructions')} outlined={activeTab !== 'Instructions'} className='w-full min-w-32' />
                                    </li>
                                    <li >
                                        <Button label={t('files')} icon="pi pi-file" onClick={() => setActiveTab('files')} outlined={activeTab !== 'files'} className='w-full' />
                                    </li>

                                </ul>
                                <div className={`${activeTab !== 'files' && 'hidden'} w-full`}>
                                    <Card title="Files">
                                        <TemplateDemo count={count} setCount={setCount} setIds={setIds} dFiles={files} setFiles={setFiles} fileUploadRef={fileUploadRef} btnSave={btnSave} />
                                    </Card>
                                </div>
                                <div className={`${activeTab !== 'Instructions' && 'hidden'} w-full`}>
                                    <Card title={t('instructions')}>
                                        <div className='flex justify-between items-center mb-2'>
                                            <div className="flex justify-end w-full  gap-2">
                                                <Dropdown value={selected} onChange={(e) => handleChange(e)} options={modules} optionLabel="name"
                                                />
                                                <Button label={t('reset')} onClick={Reset} severity="secondary" />
                                            </div>
                                        </div>
                                        <InputTextarea value={text} onChange={(e) => setText(e.target.value)} rows="20" invalid={errors.instructions} className='w-full' />
                                        {errors.instructions && (
                                            <small className="text-red-500">{errors.instructions}</small>
                                        )}
                                    </Card>
                                </div>
                                <div className={`${activeTab !== 'website' && 'hidden'} w-full`}>
                                    <Card title="Website">
                                        <div>
                                            <div>
                                                <label htmlFor="link">Crawl</label>
                                                <div className="p-inputgroup flex-1 mt-1">
                                                    <InputText placeholder="https://www.example.com" id='link' value={url} onChange={e => setUrl(e.target.value)} aria-describedby="link-help" />
                                                    <Button label="Fetch links" onClick={fetchLinks} />
                                                </div>
                                                <small id="link-help"> This will crawl all the links starting with the URL (not including files on the website). </small>
                                            </div>

                                            <div className="inline-flex items-center justify-center w-full my-4">
                                                <hr className="w-full h-1 my-8 bg-gray-200 border-0 " />
                                                <span className="absolute px-3 font-medium bg-white text-gray-900">or</span>
                                            </div>

                                            <div>
                                                <label htmlFor="sitemap">Submit Sitemap</label>
                                                <div className="p-inputgroup flex-1 mt-1">
                                                    <InputText placeholder="https://www.example.com/sitemap.xml" id='sitemap' />
                                                    <Button label="Load sitemap" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                urls.map((url, index) => (
                                                    <InputText type="text" className="p-inputtext-sm w-full mt-1" disabled value={url} key={index} />
                                                ))
                                            }
                                        </div>
                                    </Card>

                                </div>

                                <div className='w-full max-md:mt-2 md:!w-96'>
                                    <AdvancedDemo fileInputRef={fileInputRef} btnSave={btnSave} setActiveTab={setActiveTab} count={count} ids={ids} setIds={setIds} files={files} image={image} instructions={text} name={name} setErrors={setErrors} toast={toast} slug={slug} fileUploadRef={fileUploadRef} />
                                </div>
                            </div>
                        </div>
                        <Toast ref={toast} />
                    </div>
            }

        </>
    )
}


function TemplateDemo({ count, setCount, setIds, dFiles, setFiles, fileUploadRef, btnSave }) {
    const { t } = useTranslation()
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [countText, setCountText] = useState(0);

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
            files[key].text().then(data => {
                setCountText(countText + data.length)
                setCount(count + data.length)
            })
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
            file.text().then(data => {
                setCountText(countText + data.length)
                setCount(count + data.length)
            })
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });

    };

    function getImage(type) {
        switch (type) {
            case 'application/pdf':
                return '/images/pdf.png';
            case 'text/plain':
                return '/images/txt.png';
            case 'application/msword':
                return '/images/doc.png';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return '/images/docx.png';
            default:
                return '/images/x.png';
        }
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        file.text().then(data => {
            setCountText(countText - data.length)
            setCount(count - data.length)
        })
        callback();
    };

    const onTemplateClear = () => {
        setCount(count - countText)
        setCountText(0)
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={getImage(file.type)} />
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };



    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="mt-5 text-center">
                    {t('drag_drop_files_here')}
                </span>
                <span style={{ fontSize: '0.9em', color: 'var(--text-color-secondary)' }} className="mb-5 text-center">
                    {t('supported_file_types')}
                </span>
            </div>
        );
    };

    const invoiceUploadHandler = async ({ files }) => {
        await uploadFileAi(files).then((response) => {
            toast.current.show({ severity: 'success', summary: response.data.message, life: 3000 })
            setIds(response.data.ids)
            localStorage.setItem('ids', response.data.ids)
            localStorage.setItem('upload', true)

            btnSave.current.click()

        })
        if (dFiles) {
            setIds(prevIds => [
                ...prevIds,
                ...dFiles.map(file => file.file_id)
            ]);
        }
    };

    const chooseOptions = { label: t('choose'), iconOnly: false, className: 'p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined upload_files hidden', };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content={t('choose')} position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content={t('clear')} position="bottom" />

            <FileUpload ref={fileUploadRef} name="files[]" customUpload={true} uploadHandler={invoiceUploadHandler} multiple accept=".pdf, .doc, .docx, .txt" maxFileSize={10000000}
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

            <ItemDefault files={dFiles} setFiles={setFiles} setIds={setIds} />

        </div>
    )
}



function ItemDefault({ files, setFiles, setIds }) {

    const getImage = (name) => {
        const fileName = name.toLowerCase();

        if (fileName.endsWith('.pdf')) {
            return '/images/pdf.png';
        } else if (fileName.endsWith('.txt')) {
            return '/images/txt.png';
        } else if (fileName.endsWith('.doc')) {
            return '/images/doc.png';
        } else if (fileName.endsWith('.docx')) {
            return '/images/docx.png';
        } else {
            return '/images/x.png';
        }
    };

    const formatSize = (bytes) => {
        const kb = (bytes / 1024).toFixed(2);
        return `${kb} KB`;
    }

    const removeFile = (index) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index);
            const fileToRemove = prevFiles[index];
            const idToRemove = fileToRemove?.file_id;
            setIds(prevIds => prevIds.filter(id => id !== idToRemove));

            return updatedFiles;
        });
    };


    return (
        <div className='mt-4 mx-9'>
            {files && files.map((file, index) => (
                <div key={file.name} className="flex items-center flex-wrap mt-4">
                    <div className="flex items-center" style={{ width: '40%' }}>
                        <img alt={file.name} role="presentation" src={getImage(file.name)} />
                        <span className="flex flex-col text-left ml-3">
                            {file.name}
                        </span>
                    </div>
                    <Tag value={formatSize(file.bytes)} severity="success" className="px-3 py-2" />
                    <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                        onClick={() => removeFile(index)}
                    />
                </div>
            ))}
        </div>
    );
};


function AdvancedDemo({ count, ids, instructions, name, setErrors, toast, slug, files, setIds, image, setActiveTab, fileUploadRef, btnSave }) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const navigate = useNavigate();


    const store = async () => {

        setLoading(true);

        fileUploadRef.current.upload();

    }

    const update = async () => {
        let id = localStorage.getItem('ids');

        if (id) {
            id = id.split(',');
        } else {
            id = [];
        }

        const params = {
            ids: id,
            instructions: instructions,
            name: name,
            image: image,
        };

        try {
            const response = await storeChatAi(params);

            toast.current.show({
                severity: 'success',
                summary: response.data.message,
                life: 3000,
            });


            navigate('/dashboard/chatbots', { state: { message: response.data.message } });

        } catch (err) {
            handleErrors(err);

            if (err.response && err.response.status === 403) {
                toast.current.show({
                    severity: 'warn',
                    summary: "Warning",
                    detail: err.response.data.message,
                    life: 3000,
                });
            }

        }

        setLoading(false);

        localStorage.removeItem('ids');
        localStorage.removeItem('upload');


    }

    function handleErrors(err) {
        setErrors({ name: null, instructions: null });
        if (err.response && err.response.status === 422) {
            const newErrors = {};
            if (err.response.data.errors && err.response.data.errors.name) {
                newErrors.name = err.response.data.errors.name[0];
            }
            if (err.response.data.errors && err.response.data.errors.instructions) {
                newErrors.instructions = err.response.data.errors.instructions[0];
                setActiveTab('Instructions')
            }
            setErrors(newErrors);
        }
    }

    const footer = (
        <>
            <Button className='hidden' onClick={update} ref={btnSave} />
            <Button label={t('create_chatbot')} icon="pi pi-check" className='w-full' loading={loading} onClick={store} />
        </>
    );
    const title = (
        <>
            <p className="text-center">{t('sources')}</p>
        </>
    )

    return (
        <Card title={title} subTitle={t('total_detected_characters')} footer={footer}>
            <p className="m-0 text-center">
                {count}<span className='text-gray-400'></span>
            </p>
        </Card>
    )
}