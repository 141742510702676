import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRouteAdmin = ({ element: Component, ...rest }) => {
    // Check if the user is authenticated
    const isAuthenticated = useSelector(state => state.auth.user !== null);
    // Check if the authenticated user is an admin
    const isAdmin = useSelector(state => state.auth.user?.admin == 1);

    // If the user is authenticated and an admin, render the component
    if (isAuthenticated && isAdmin) {
        return <Component {...rest} />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    // If not, redirect to a page not found or unauthorized page
    return <Navigate to="/page-not-found" />;
};

export default PrivateRouteAdmin;
