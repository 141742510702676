import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const getAis = async () => {
    await Csrf.getCookie();
    return await Api.get("/getAis");
}


export const createTicket = async (params) => {
    await Csrf.getCookie();
    return await Api.post("/createTicket", params);
}

export const getTickets = async () => {
    await Csrf.getCookie();
    return await Api.get("/getTickets");
}


export const getTicket = async (id) => {
    await Csrf.getCookie();
    return await Api.get("/getTicket/" + id);
}

export const addMessageToTicket = async (params) => {
    await Csrf.getCookie();
    return await Api.post("/addMessageToTicket", params);
}