import React from 'react'
import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';

export default function DocsIndex() {
    return (
        <div>
            <h1 className='text-2xl font-bold'>Getting Setup</h1>
            <p className='mt-2'>This page will help you get started with DealogEase.</p>

            <hr className='my-5' />

            <p>
                DealogEase offers an API for you to interact with your chatbots if you are subscribed to a paid plan. To get an API key Token, go to
                <span className='code'>https://app.dialogease.com/dashboard/settings</span>
                and copy the API Token
            </p>

            <Image src="/images/api_tokens.png" alt="Api Token" className='w-full mt-3' preview />

            <p>
                To get the ID of a created Chatbot, navigate to  <span className='code'>https://app.dialogease.com/dashboard/chatbot/[your slug chatbot]/settings/general</span>
                and copy on the chatbot you would like to get the ID for.
            </p>

            <Image src="/images/chatbot_id.png" alt="Api Token" className='w-full mt-3' preview />

        </div>
    )
}



