import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { deleteChatAi, fetchChatAi } from '../../services/ChatAi';
import { Card } from 'primereact/card';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'primereact/skeleton';
import { useLocation, useNavigate } from 'react-router-dom';


export default function Dashboard() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ais, setAis] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [message, setMessage] = useState(location.state?.message);
    const toast = useRef(null);
    const [maxChatbots, setMaxChatbots] = useState(0);


    useEffect(() => {
        setLoading(true);
        fetchChatAi().then((response) => {
            setAis(response.data.ais);
            setMaxChatbots(response.data.maxChatbots);
            setLoading(false);
        })
    }, [])



    useEffect(() => {
        if (message) {
            toast.current.show({ severity: 'success', summary: message, life: 3000 });
            setTimeout(() => {
                setMessage(null);
                navigate(location.pathname, { replace: true, state: {} });
            }, 3000);
        }
    }, [message]);


    useEffect(() => {
        document.title = 'DialogEase - ' + t('chatbots')
    }, [])

    return (
        <div className="px-2">
            <div className="mx-auto flex max-w-8xl flex-row justify-between py-5">
                <h4 className="my-1 text-3xl font-bold">{t("chatbots")}</h4>
                <Button
                    label={t("new_chatbot")}
                    icon="pi pi-plus"
                    iconPos="right"
                    size="small"
                    disabled={ais.length >= maxChatbots}
                    onClick={() =>
                        navigate("/dashboard/chatbots/create-new-chatbot")
                    }
                />
            </div>

            <div
                className={`grid gap-3 mx-auto max-sm:justify-center ${ais.length > 4 || loading && "justify-center"}`}
            >
                {
                    loading ? <CardSkeleton /> :

                        ais.map((ai, index) => (
                            <CardDemo
                                key={index}
                                data={ai}
                                index={index}
                                navigate={navigate}
                            />
                        ))}

                {
                    !ais.length && !loading &&
                    <div className='flex justify-center w-full font-semibold p-10'>
                        {t('there_are_no_assistants')}
                    </div>
                }

            </div>

            <Toast ref={toast} />

        </div>
    );
}


function CardDemo({ data, index, navigate }) {
    const urlCustomBot = `custombot/${data.slug}`;
    const urlEditBot = `editbot/${data.slug}`;
    const urlBotTools = `botTools/${data.slug}`;
    const urlBot = `/dashboard/chatbot/${data.slug}`;
    const toast = useRef(null);

    const accept = () => {
        deleteChatAi(data.id).then((res) => {
            toast.current.show({ severity: 'success', summary: res.data.message, life: 3000 });
        });
    };

    const reject = () => {
    };
    const destroyChatAi = (event) => {


        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this record?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    const header = (
        <div className='flex justify-center'>
            <img alt={'Card' + index} src={data.image} className='w-12rem h-12rem rounded-md p-2' />
        </div>
    );
    const footer = (
        <>
            <div className='flex gap-1 justify-center'>
                <Button onClick={() => navigate(urlEditBot)} rounded icon="pi pi-pen-to-square" size='small' tooltip="Edit" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button onClick={() => navigate(urlBotTools)} rounded severity="warning" icon="pi pi-wrench" size='small' tooltip="Tools" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button onClick={() => navigate(urlCustomBot)} rounded severity="info" icon="pi pi-cog" size='small' tooltip="Custom Bot" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button onClick={destroyChatAi} rounded severity="danger" icon="pi pi-trash" size='small' tooltip="Delete" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </div>
        </>
    );
    const title = (
        <>
            <hr />
            <p className='text-center text-[18px] p-2 font-roboto font-semibold'>{data.name}</p>
        </>

    )

    return (
        <div className="card flex justify-content-center">
            <Card title={title} header={header} className="w-19rem cursor-pointer" onClick={() => navigate(urlBot)}>
            </Card>
            <Toast ref={toast} />
            <ConfirmPopup />
        </div>
    )
}


function CardSkeleton() {

    const numbers = Array.from({ length: 4 }, (_, index) => index);

    const header = (
        <div className='flex justify-center p-2'>
            <Skeleton shape="circle" size="12rem"></Skeleton>
        </div>
    );

    const title = (
        <>
            <hr />
            <div className=' flex justify-center p-2'>
                <Skeleton height="2rem" width='12rem' className="mb-2"></Skeleton>
            </div>
        </>

    )

    return (
        <>
            {numbers.map((item, index) => (
                <div key={index} className="card flex justify-content-center" >
                    <Card title={title} header={header} className="w-19rem cursor-pointer">
                    </Card>
                </div>
            ))}


        </>



    )
}