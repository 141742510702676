import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    return (
        <>
            <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('settings')}</h4>
            </div>

            <div className='md:flex gap-5 block'>
                <div className='md:w-20rem w-full p-card p-2 '>
                    <ul className="gap-2  flex justify-center md:block">
                        <li>
                            <NavLink to="general" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-cog"></i>
                                {t('general')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="plans" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-th-large"></i>
                                {t('plans')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="billing" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-credit-card"></i>
                                {t('billing')}
                            </NavLink>
                        </li>


                    </ul>
                </div>
                <div className='w-full'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}
