
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'; // For loading translation files
import LanguageDetector from 'i18next-browser-languagedetector'; // For detecting user language

i18n
    .use(Backend) // Load translations using http (default public/locales)
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next) // Pass i18n instance to react-i18next
    .init({
        fallbackLng: 'en',
        debug: false, // Set to false in production

        interpolation: {
            escapeValue: false, // React already safes from xss
        },

        backend: {
            // Path where resources get loaded from
            loadPath: '/api/translations/{{lng}}'
        },

        detection: {
            // Set to 'path' to detect language from the URL path
            order: ['path', 'cookie', 'localStorage', 'navigator'],
            caches: ['cookie'],
            // Here, you can specify which part of the URL contains the language code
            lookupFromPathIndex: 1, // This indicates the first segment of the path, e.g., '/en/...'
        }
    });

export default i18n;
