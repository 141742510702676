import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card';
import { destroyLead, editLead, fLeads, LeadLogWhitId } from '../../../../services/ChatAi';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { ChatTemplate } from './ChatLogs';
import moment from 'moment-timezone';
import { Calendar } from 'primereact/calendar';
import { useOutletContext } from 'react-router-dom';


export default function Leads() {
    const { setParentDate, parentDate } = useOutletContext();
    const { t } = useTranslation()
    const { slug } = useParams()
    const dt = useRef(null);
    const toast = useRef(null);
    const [leads, setLeads] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [date, setDate] = useState('last_7_days');
    const [lead, setLead] = useState({});
    const [visible, setVisible] = useState(false);
    const [visibleConversation, setVisibleConversation] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentChat, setCurrentChat] = useState({});
    const [customDate, setCustomDate] = useState(null);
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState([
        { field: 'date', header: t('date_leads') },
        { field: 'firstName', header: t('first_name') },
        { field: 'lastName', header: t('last_name') },
        { field: 'phone', header: t('phone') },
        { field: 'email', header: t('email') },
        { field: 'cp', header: t('cp') },
        { field: 'ip', header: t('ip') },
        { field: 'action', header: t('Actions') },
    ]);


    const dates = [
        { name: t('all_time'), value: 'all_time' },
        { name: t('today'), value: 'today' },
        { name: t('yesterday'), value: 'yesterday' },
        { name: t('last_7_days'), value: 'last_7_days' },
        { name: t('last_30_days'), value: 'last_30_days' },
        { name: t('this_month'), value: 'this_month' },
        { name: t('last_month'), value: 'last_month' },
        { name: t('this_year'), value: 'this_year' },
        { name: t("last_year"), value: 'last_year' },
        { name: t('last_24_hours'), value: 'last_24_hours' },
        { name: t('last_48_hours'), value: 'last_48_hours' },
        { name: t('last_7_hours'), value: 'last_7_hours' },
        { name: t('custom_dates'), value: 'custom' },
    ];

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function fetchLeads(search, perPage, date, customDate = null, page = 1) {
        fLeads(slug, date, page, perPage, customDate, search).then((res) => {
            setColumns([
                { field: 'date', header: t('date_leads') },
                { field: 'firstName', header: t('first_name') },
                { field: 'lastName', header: t('last_name') },
                { field: 'phone', header: t('phone') },
                { field: 'email', header: t('email') },
                { field: 'cp', header: t('cp') },
                { field: 'ip', header: t('ip') },
                { field: 'action', header: t('Actions') },
            ])

            // setPerPage(res.data.per_page);
            setTotal(res.data.total);
            var data = [];
            var leads = res.data.leads;

            let allEmailsEmpty = true;
            let allLastNameEmpty = true;
            let allFirstNameEmpty = true;
            let allPhoneEmpty = true;

            leads.forEach(lead => {
                let tab = JSON.parse(lead.data);
                let filteredTab = { ...tab };

                ['firstName', 'lastName', 'email', 'adresse', 'phone'].forEach(key => {
                    delete filteredTab[key];
                });

                data.push({
                    id: lead.id,
                    conversation_id: lead.conversation_id,
                    firstName: tab.firstName || '',
                    lastName: tab.lastName || '',
                    email: tab.email || '',
                    phone: tab.phone || '',
                    adresse: tab.adresse || '',
                    cp: Object.keys(filteredTab).length ? JSON.stringify(filteredTab) : '',
                    ip: lead.ip,
                    date: lead.created_at
                });

                if (tab.email) allEmailsEmpty = false;
                if (tab.firstName) allFirstNameEmpty = false;
                if (tab.lastName) allLastNameEmpty = false;
                if (tab.phone) allPhoneEmpty = false;

            });

            if (data.length > 0) {
                if (allEmailsEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'email'));
                }
                if (allFirstNameEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'firstName'));
                }

                if (allLastNameEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'lastName'));
                }

                if (allPhoneEmpty) {
                    setColumns(prevColumns => prevColumns.filter(column => column.field !== 'phone'));
                }
            }

            setLeads(data)

        }).catch(err => {
            if (err.response.status === 404) {
                setLeads([])
                setTotal(0)
            }
        });
    }

    useEffect(() => {
        fetchLeads(search, perPage, parentDate, customDate)
    }, [slug, perPage])

    const onPageChange = (event) => {
        setFirst(event.first);
        fetchLeads(search, perPage, parentDate, customDate, event.page + 1)
    };


    function handleChange(event) {
        // setDate(event.value);
        setParentDate(event.value);
        if (event.value !== 'custom') {
            setCustomDate(null)
            fetchLeads(search, perPage, event.value)
        }
    }

    function handleChangeSearch(value) {
        setSearch(value)
        fetchLeads(value, perPage, parentDate, customDate)
    }


    function handleChangeCustom(event) {
        setCustomDate(event.value)
        fetchLeads(search, perPage, parentDate, event.value)
        // console.log(event.value)
    }

    const template = (product) => {
        const cp = product.cp ? JSON.parse(product.cp) : {};
        return (
            <div>
                {
                    Object.keys(cp).map((key, index) => (
                        <p key={index}>
                            <b> {key}</b>: {cp[key]} <br />
                        </p>
                    ))
                }
            </div >
        );
    };

    const handleSelectLead = (id) => {
        setVisibleConversation(true);
        setMessages([])
        setCurrentChat({})
        LeadLogWhitId(id).then(res => {
            setMessages(res.data.messages);
            setCurrentChat({ url: res.data.url, created_at: res.data.created_at });
        })
    };

    function edit(data) {
        setLead(data);
        setVisible(true);
    }

    function deleteLead(id) {
        destroyLead(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setLeads(prevLeads => prevLeads.filter(l => l.id !== id));
        })
    }


    const actions = (data) => {

        return (
            <div className='flex gap-1'>
                <Button icon="pi pi-comment" severity="secondary" rounded aria-label="conversation" onClick={() => handleSelectLead(data.conversation_id)} />
                <Button icon="pi pi-pen-to-square" severity="info" rounded aria-label="edit" onClick={() => edit(data)} />
                <Button icon="pi pi-trash" rounded severity="danger" aria-label="delete" onClick={() => deleteLead(data.id)} />
            </div>
        );
    };


    function customDateBody(data) {
        return moment(data.date).format('DD/MM/YYYY HH:mm');
    }


    useEffect(() => {
        document.title = 'DialogEase - ' + t('activity') + ' - ' + t('leads');
    }, [])


    return (
        <>
            <Card className='notBorderB'>
                <div>
                    <div className='flex justify-between items-center mb-2'>

                        <div className='flex gap-2'>

                            <InputText value={search} placeholder={t('search')} onChange={(e) => handleChangeSearch(e.target.value)} />

                            <Dropdown value={parentDate} onChange={(e) => handleChange(e)} options={dates} optionLabel="name" className='min-w-44'
                                placeholder={dates[0].name} />

                            {parentDate == 'custom' &&
                                <Calendar value={customDate} onChange={(e) => handleChangeCustom(e)} selectionMode="range" readOnlyInput hideOnRangeSelection dateFormat="yy/mm/dd" />
                            }
                        </div>

                        <Button type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" className='mb-2' />
                    </div>
                    <DataTable ref={dt} value={leads} tableStyle={{ minWidth: '90rem' }} emptyMessage="No Leads found."
                        paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport "
                        currentPageReportTemplate={t('pagination_text', { 'first': '{first}', 'end': '{last}', 'total': '{totalRecords}' })}
                    >
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header}
                                sortable={['firstName', 'lastName', 'phone', 'email', 'ip', 'date'].includes(col.field)}
                                style={['firstName', 'lastName'].includes(col.field) && { width: '10rem' }}
                                body={col.field === 'action' ? actions : col.field === "date" ? customDateBody : col.field === 'cp' && template} className={` ${col.field == 'cp' && 'max-w-64'}`} />
                        ))}
                    </DataTable>
                    {
                        total > 0 && <div className='flex items-center justify-center'>
                            <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
                            <Dropdown value={perPage} onChange={(e) => setPerPage(e.value)} options={[10, 20, 30, 50, 100]} className="me-3" />
                            <p>{t('pagination_text', { 'first': first + 1, 'end': first + leads.length, 'total': total })}</p>
                        </div>
                    }

                </div >
            </Card >

            <Sidebar visible={visibleConversation} position="right" onHide={() => setVisibleConversation(false)} className="w-full md:w-20rem lg:w-30rem">
                <div>
                    <ChatTemplate messages={messages} data={currentChat} toast={toast} />
                </div>
            </Sidebar>


            <Dialog header={t('edit_lead')} visible={visible} modal={false} style={{ width: '30vw' }} onHide={() => { if (!visible) return; setVisible(false); }}
                breakpoints={{ '1280px': '50vw', '1024px': '70vw', '641px': '100vw' }}
            >
                <EditForm lead={lead} setLeads={setLeads} setVisible={setVisible} toast={toast} />
            </Dialog>
            <Toast ref={toast} />
        </>
    )
}

function EditForm({ lead, setLeads, setVisible, toast }) {
    const { t } = useTranslation()


    const [formData, setFormData] = useState({
        firstName: lead.firstName || '',
        lastName: lead.lastName || '',
        phone: lead.phone || '',
        email: lead.email || '',
        adresse: lead.adresse || '',
        cp: lead.cp ? JSON.parse(lead.cp) : {},
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };
    const handleCpChange = (key, value) => {
        setFormData((prevState) => ({
            ...prevState,
            cp: {
                ...prevState.cp,
                [key]: value,
            },
        }));
    };

    function save() {
        editLead(lead.id, formData).then(res => {

            const finalFormData = {
                ...formData,
                cp: JSON.stringify(formData.cp),
            };

            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setLeads(prevLeads => prevLeads.map(l => (l.id === lead.id ? { ...l, ...finalFormData } : l)));
            setVisible(false)
        })
    }


    return (
        <div>

            <div className="mb-3">
                <label htmlFor="firstName" className="block text-900 font-medium mb-2">{t('first_name')}</label>
                <InputText id="firstName" type="text" placeholder={t('first_name')} className="w-full"
                    value={formData.firstName}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="lastName" className="block text-900 font-medium mb-2">{t('last_name')}</label>
                <InputText id="lastName" type="text" placeholder={t('last_name')} className="w-full"
                    value={formData.lastName}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="email" className="block text-900 font-medium mb-2">{t('email')}</label>
                <InputText id="email" type="text" placeholder={t('email')} className="w-full"
                    value={formData.email}
                    onChange={handleInputChange}
                />
            </div>


            <div className="mb-3">
                <label htmlFor="phone" className="block text-900 font-medium mb-2">{t('phone')}</label>
                <InputText id="phone" type="text" placeholder={t('phone')} className="w-full"
                    value={formData.phone}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="adresse" className="block text-900 font-medium mb-2">{t('adresse')}</label>
                <InputText id="adresse" type="text" placeholder={t('adresse')} className="w-full"
                    value={formData.adresse}
                    onChange={handleInputChange}
                />
            </div>

            {
                Object.keys(formData.cp).length > 0 &&

                <div className="mb-3">
                    <label htmlFor="cp" className="block text-900 font-medium mb-2">{t('cp')}</label>

                    {Object.entries(formData.cp).map(([key, value]) => (
                        <div key={key} className="mb-2">
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                    {key}
                                </span>
                                <InputText
                                    id={key}
                                    type="text"
                                    placeholder={t(key)}
                                    className="w-full"
                                    value={value}
                                    onChange={(e) => handleCpChange(key, e.target.value)}
                                />
                            </div>


                        </div>
                    ))}

                </div>
            }




            <div>
                <Button type="submit" label={t('save')} className="w-full" onClick={save} />
            </div>


        </div>
    )
}
