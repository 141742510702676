import React from 'react'
import NavBar from './NavBar'
import { BrowserRouter, Routes, Route, Navigate, Outlet, NavLink } from "react-router-dom";
import Footer from '../dashboard/Footer';
import { useTranslation } from 'react-i18next';
import SideBar from './SideBar';


export default function Layout() {
    const { t } = useTranslation();

    return (
        <div>
            <NavBar />

            <div className='block md:flex'>
                <SideBar />
                <div className='w-full mx-3'>
                    <Outlet />
                </div>
            </div>

            {/* <div className='max-w-screen-xl mx-auto mt-1 min-h-screen'>
                <Outlet />
            </div> */}
            <Footer />
        </div>
    )
}
