import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import NavBar from './NavBar';


export default function Layout({ children }) {

    return (
        <div>
            <NavBar />
            <div className='max-w-screen-xl mx-auto mt-1 min-h-screen'>
                {children}
            </div>
        </div>
    )
}
