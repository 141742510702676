import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { getUser, updatePassword, updateUser } from '../../../services/Profile';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment-timezone';

export default function General() {
    const { t } = useTranslation()
    const toast = useRef(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingPassword, setLoadingPassword] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [token, setToken] = useState("");
    const [tokenChiffre, setTokenChiffre] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [timezone, setTimezone] = useState("");
    const timezones = moment.tz.names().map((tz) => ({ label: tz, value: tz }));
    const countries = [
        { name: 'English', value: 'en' },
        { name: 'Français', value: 'fr' },
        { name: 'العربية', value: 'ar' },
    ];



    const copyToClipboard = () => {
        navigator.clipboard.writeText(token).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    };

    function save() {
        setLoading(true);
        setValidationErrors({});
        let params = {
            name,
            email,
            lang: selectedCountry,
            timezone
        };
        updateUser(params)
            .then((res) => {
                toast.current.show({ severity: 'success', summary: "Success", detail: res.data.message });
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error.response.data.errors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function maskString(input) {
        if (!input || input.length <= 10) {
            return input;
        }

        const start = input.slice(0, 10); // Garder les 10 premiers caractères
        const end = input.slice(-10);    // Garder les 10 derniers caractères
        const maskedSection = '*'.repeat(input.length - 20); // Masquer le reste

        return `${start}${maskedSection}${end}`;
    }
    function savePassword() {
        setLoadingPassword(true)

        setValidationErrors({});

        let params = {
            oldPassword,
            password,
            password_confirmation: cPassword
        }

        updatePassword(params).then((res) => {
            toast.current.show({ severity: 'success', summary: "Success", detail: res.data.message });
            setCPassword('')
            setPassword('')
            setOldPassword('')
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                setValidationErrors(error.response.data.errors);
            }
        }).finally(() => {
            setLoadingPassword(false)
        })
    }


    useEffect(() => {
        setLoad(true);
        getUser()
            .then((res) => {
                setName(res.data.user.name);
                setEmail(res.data.user.email);
                setSelectedCountry(res.data.user.lang);
                setToken(res.data.user.api_token);
                setTokenChiffre(maskString(res.data.user.api_token));
                setTimezone(res.data.user.timezone || "UTC");
                setLoad(false);
            });
    }, []);

    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('general');
    }, [])

    return (
        <>
            <Card title={t('profile')}>
                <div>
                    <div>
                        <label className="block text-sm font-medium mb-2">{t('name')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' invalid={!!validationErrors.name} />

                        }

                        {!!validationErrors.name && (
                            <small className="text-red-500">{validationErrors.name[0]}</small>
                        )}


                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('email')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={email} onChange={(e) => setEmail(e.target.value)} className='w-full ' invalid={!!validationErrors.email} />
                        }

                        {!!validationErrors.email && (
                            <small className="text-red-500">{validationErrors.email[0]}</small>
                        )}

                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('api_token')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                : <>
                                    <div className='p-3 rounded-lg select-none bg-gray-200 relative'>
                                        {tokenChiffre}
                                        <span className='absolute  ltr:right-2 rtl:left-2 flex justify-center items-center top-0 h-full'>
                                            <Button size='small' icon={isCopied ? 'pi pi-check' : 'pi pi-copy'} outlined severity={isCopied ? 'success' : 'secondary'} onClick={copyToClipboard} />
                                        </span>

                                    </div>
                                </>
                        }
                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('timezone')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <Dropdown
                                    value={timezone}
                                    onChange={(e) => setTimezone(e.value)}
                                    options={timezones}
                                    optionLabel="label"
                                    placeholder={t('select_timezone')}
                                    filter // Adds a search filter to handle the large list
                                    className="w-full"
                                />
                        }
                        {!!validationErrors.timezone && (
                            <small className="text-red-500">{validationErrors.timezone[0]}</small>
                        )}
                    </div>
                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('langage_account')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Select a Country"
                                    className="w-full" />
                        }
                    </div>

                    <div className='mt-4'>

                        {
                            !load &&
                            <div className='mt-2 flex justify-end'>
                                <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={save} loading={loading} />
                            </div>
                        }
                    </div>

                </div>
            </Card>

            <Card title={t('change_password')} className='mt-6'>
                <div>
                    <div>
                        <label className="block text-sm font-medium mb-2">{t('current_password')}</label>
                        <InputText value={oldPassword} type="password" onChange={(e) => setOldPassword(e.target.value)} className='w-full' invalid={!!validationErrors.oldPassword} />
                        {!!validationErrors.oldPassword && (
                            <small className="text-red-500">{validationErrors.oldPassword[0]}</small>
                        )}
                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('password')}</label>
                        <InputText value={password} type="password" onChange={(e) => setPassword(e.target.value)} className='w-full ' invalid={!!validationErrors.email} />
                        {!!validationErrors.password && (
                            <small className="text-red-500">{validationErrors.password[0]}</small>
                        )}
                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('confirm_password')}</label>
                        <InputText value={cPassword} type="password" onChange={(e) => setCPassword(e.target.value)} className='w-full' />
                    </div>
                    <div className='mt-4'>
                        <div className='mt-2 flex justify-end'>
                            <Button label={t('save')} className='min-w-32' icon="pi pi-check" onClick={savePassword} loading={loadingPassword} />
                        </div>
                    </div>


                </div>
            </Card>

            <Toast ref={toast} />
        </>
    )
}
